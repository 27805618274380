<template>
  <div class="card new">
    <div class="row-center">
      <div>
        <h2>Состав маршрута</h2>
      </div>
      <div class="button small" v-if="canAdd" style="margin-left: auto">
        <button class="white bg-prime-prime-blue" @click="onAdd">
          Добавить
        </button>
      </div>
    </div>

    <table style="margin: 16px 0">
      <thead>
        <th width="20px">#</th>
        <th width="80px">Услуга</th>
        <th width="70px">Дата</th>
        <th width="40px">Время</th>
        <th width="120px">Допуслуга</th>
        <th width="300px">Адрес</th>
        <th>Комментарий</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in route" :key="item.id" class="cursor">
          <td>{{ index + 1 }}</td>
          <td
            @click="editType(index, item)"
            :class="{
              green: item.typeID == loading,
              blue: item.typeID == unloading,
              gray: item.typeID == additional,
            }"
          >
            {{ item.typeTitle }}
          </td>
          <td @click="editDate(index, item)">{{ item.dateStr }}</td>
          <td @click="editTime(index, item)">{{ item.time }}</td>
          <td @click="editAdditional(index, item)">
            {{ getAdditionalTitle(item.additionalID) }}
          </td>
          <td @click="editAddress(index, item)">{{ item.address }}</td>
          <td @click="editComment(index, item)">
            <div class="cell22">
              {{ item.comment }}
            </div>
          </td>
          <td width="50px;">
            <div
              :class="{
                'state-new': item.state == 1,
                'state-error-400': item.state == 3,
                'state-error': item.state == 5,
              }"
            >
              {{ item.stateTitle }}
            </div>
          </td>
        </tr>
        <tr v-if="route.length == 0">
          <td colspan="10">
            <i>Маршрут не задан</i>
          </td>
        </tr>
      </tbody>
    </table>

    <route-type-dlg ref="routeTypeDlg" />
    <select-date-dlg ref="selectDateDlg" />
    <select-time-dlg ref="selectTimeDlg" />
    <select-dlg ref="selectDlg" />
    <find-address-dlg ref="findAddressDlg" />
    <text-dlg ref="textDlg" />
  </div>
</template>

<script>
import RouteTypeDlg from "@/components/modals/order/RouteTypeDlg.vue";
import SelectDateDlg from "@/components/modals/app/SelectDateDlg.vue";
import SelectTimeDlg from "@/components/modals/app/SelectTimeDlg.vue";
import SelectDlg from "@/components/modals/app/SelectDlg.vue";
import FindAddressDlg from "@/components/modals/FindAddressDlg.vue";
import TextDlg from "@/components/modals/app/TextDlg";

export default {
  name: "OrderRoute",

  components: {
    RouteTypeDlg,
    SelectDateDlg,
    SelectTimeDlg,
    SelectDlg,
    FindAddressDlg,
    TextDlg,
  },

  computed: {
    canAdd() {
      return true;
    },
  },

  data: () => ({
    route: [],

    serviceAdditional: [],

    // константы
    loading: 1,
    unloading: 2,
    additional: 3,
  }),

  // Список типов узлов маршрута
  // 1 - загрузка
  // 2 - выгрузка
  // 3 - допуслуги

  mounted() {
    this.loadServiceAdditional();
    this.load();
  },

  methods: {
    async load() {
      const result = await this.$api.order.getRoute(
        this.$store.getters["order/getID"]
      );
      if (result.list) {
        this.route = result.list;
      }
    },

    async loadServiceAdditional() {
      this.serviceAdditional = [];
      const result = await this.$api.services.getAdditional();
      if (result.list) {
        this.serviceAdditional = result.list;
        this.serviceAdditional.forEach((element, index) => {
          this.serviceAdditional[index].index = element.id;
        });

        this.serviceAdditional.unshift({ id: 0, index: 0, title: "" });
      }
    },

    async onAdd() {
      const result = await this.$refs.routeTypeDlg.show({
        value: { typeID: 1 }, // 1 - это загрузка
        okButton: "Создать",
      });
      if (result.ok) {
        const result2 = await this.$api.order.createRouteNode(
          this.$store.getters["order/getID"],
          result.value
        );
        if (result2.id) {
          this.load();
        }
      }
    },

    async editType(index, node) {
      const result = await this.$refs.routeTypeDlg.show({
        value: { typeID: node.typeID },
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].typeID = result.value.typeID;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },

    async editDate(index, node) {
      const result = await this.$refs.selectDateDlg.show({
        value: { date: node.date },
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].date = result.value.date;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },
    async editTime(index, node) {
      const result = await this.$refs.selectTimeDlg.show({
        value: { time: node.time },
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].time = result.value.time;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },

    async editAdditional(index, node) {
      if (node.typeID != 3) {
        return;
      }
      const result = await this.$refs.selectDlg.show({
        title: "Выберите наименование дополнительной услуги",
        value: node.additionalID,
        list: this.serviceAdditional,
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].additionalID = result.value;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },

    getAdditionalTitle(id) {
      const item = this.serviceAdditional.find((el) => el.id === id);
      return item ? item.title : "";
    },

    getUniqueAddress() {
      let result = [];

      this.route.forEach((element) => {
        if (element.addressID > 0) {
          if (result.findIndex((el) => el.id === element.addressID) == -1) {
            result.push({
              id: element.addressID,
              addressline: element.address,
            });
          }
        }
      });

      return result;
    },

    async editAddress(index, node) {
      const result = await this.$refs.findAddressDlg.show({
        value: node.additionalID,
        existList: this.getUniqueAddress(),
        okButton: "Cохранить",
      });
      if (result.ok) {
        if (result.value.id == 0) {
          // Это новый адрес, его надо добавить
          const result3 = await this.$api.address.create(result.value);
          if (result3.id) {
            result.value.id = result3.id;
          }
        }
        if (result.value.id > 0) {
          this.route[index].addressID = result.value.id;
          const result2 = await this.$api.order.updateRoute(
            this.$store.getters["order/getID"],
            this.route[index]
          );
          if (result2.ok) {
            this.load();
          }
        }
      }
    },
    async editComment(index, node) {
      const result = await this.$refs.textDlg.show({
        title: "Текст комментария",
        value: node.comment,
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].comment = result.value;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },
  },
};
</script>

<style scoped>
.green {
  background-color: #e0eee0;
}

.blue {
  background-color: #dff3f9;
}

.gray {
  background-color: #e2e2e2;
}

.cell22 {
  white-space: nowrap; /* По желанию запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* По желанию добавляем многоточие */
  max-width: 300px;
}
</style>
