<template>
  <input type="text" v-model="value" @keydown="onKey" :disabled="disabled" />
</template>

<script>
export default {
  name: "InputControl",

  props: {
    initvalue: String,
    disabled: Boolean,
    reinit: Boolean,
  },

  emits: ["accept"],

  watch: {
    initvalue: {
      handler(newValue) {
        this.value = newValue;
      },
      deep: true,
    },
    reinit: {
      handler() {
        this.value = this.initvalue;
      },
      deep: true,
    },
  },

  data: () => ({
    value: "",
    waitTimer: null,
  }),

  mounted() {
    this.value = this.initvalue;
  },

  methods: {
    onKey() {
      if (this.waitTimer) {
        clearTimeout(this.waitTimer);
      }
      this.waitTimer = setTimeout(this.accept, 400);
    },

    accept() {
      this.$emit("accept", { value: this.value });
    },
  },
};
</script>

<style></style>
