<template>
  <div class="card new">
    <div class="row-center">
      <div>
        <h2>Перевозимый груз</h2>
      </div>
    </div>

    <table style="margin: 16px 0">
      <thead>
        <th width="20px">#</th>
        <th width="80px">Услуга</th>
        <th width="70px">Дата</th>
        <th width="40px">Время</th>
        <th width="300px">Адрес</th>
        <th width="100px">Груз</th>
        <th></th>
        <th>Описание груза</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in route" :key="item.id" class="cursor">
          <td>{{ index + 1 }}</td>
          <td
            :class="{
              green: item.typeID == loading,
              blue: item.typeID == unloading,
              gray: item.typeID == additional,
            }"
          >
            {{ item.typeTitle }}
          </td>
          <td>{{ item.dateStr }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.address }}</td>
          <td @click="editCargo(index, item)">
            {{ getCargoType(item.cargo.typeID) }}
          </td>
          <td @click="editCargo(index, item)">
            {{ getCargoCatagory(item.cargo.categoryID) }}
          </td>
          <td @click="editCargo(index, item)">{{ item.cargo.comment }}</td>
        </tr>
        <tr v-if="route.length == 0">
          <td colspan="10">
            <i>Маршрут не задан</i>
          </td>
        </tr>
      </tbody>
    </table>

    <cargo-dlg ref="cargoDlg" />
  </div>
</template>

<script>
import CargoDlg from "@/components/modals/order/CargoDlg.vue";

export default {
  name: "OrderCargo",

  components: { CargoDlg },

  computed: {
    canAdd() {
      return true;
    },
  },

  data: () => ({
    route: [],
    cargoTypes: [],
    cargoCategories: [],

    // константы
    loading: 1,
    unloading: 2,
    additional: 3,
  }),

  mounted() {
    this.loadCargoTypes();
    this.loadCargoCategories();
    this.load();
  },

  methods: {
    async load() {
      const result = await this.$api.order.getRouteForCargo(
        this.$store.getters["order/getID"]
      );
      if (result.list) {
        this.route = result.list;
      }
    },

    async loadCargoTypes() {
      const result = await this.$api.order.getCargoTypes();
      if (result.list) {
        this.cargoTypes = result.list;
      }
    },

    async loadCargoCategories() {
      const result = await this.$api.order.getCargoCategories();
      if (result.list) {
        this.cargoCategories = result.list;
      }
    },

    async editCargo(index, node) {
      const result = await this.$refs.cargoDlg.show({
        value: node.cargo,
        okButton: "Cохранить",
      });
      if (result.ok) {
        this.route[index].cargo = result.value;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.route[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },

    getCargoType(id) {
      const item = this.cargoTypes.find((el) => el.id === id);
      return item ? item.title : "";
    },

    getCargoCatagory(id) {
      const item = this.cargoCategories.find((el) => el.id === id);
      return item ? item.title : "";
    },
  },
};
</script>

<style scoped>
.green {
  background-color: #e0eee0;
}

.blue {
  background-color: #dff3f9;
}
</style>
