<template>
  <div>
    <div class="half m-right-16">
      <div class="row-center">
        <div><h1>Отчеты по запросам</h1></div>
      </div>
      <div class="new label" style="margin: 20px 0 10px">Диапазон дат</div>
      <div class="row-center" style="gap: 8px">
        <div>Начало периода</div>
        <div class="quarter">
          <VueDatePicker
            v-model="dateFrom"
            model-type="yyyy-MM-dd"
            format="dd.MM.yyyy"
          ></VueDatePicker>
        </div>
        <div>Конец периода</div>
        <div class="quarter">
          <VueDatePicker
            v-model="dateTo"
            model-type="yyyy-MM-dd"
            format="dd.MM.yyyy"
          ></VueDatePicker>
        </div>
      </div>
      <!--
          <VueDatePicker
            v-model="date"
            model-type="yyyy-MM-dd"
            format="dd.MM.yyyy"
            range
            multi-calendars
          ></VueDatePicker>
        -->
      <div class="form card new">
        <div class="label">Выдерите отчет</div>
        <div>
          <select
            v-model="reporttypeID"
            @change="
              () => {
                showReportID = 0;
                includeFired = false;
              }
            "
          >
            <option value="0"></option>
            <option v-for="item in reporttypes" :key="item.id" :value="item.id">
              {{ item.title }}
            </option>
          </select>
          <label
            class="row-center"
            style="margin-top: 16px"
            v-if="reporttypeID == 2"
          >
            <input
              type="checkbox"
              style="width: 30px; margin-right: 10px"
              v-model="includeFired"
            />
            Включить уволенных сотрудников
          </label>
        </div>
      </div>

      <div class="row new">
        <div class="button">
          <button class="white bg-prime-prime-blue" @click="onCreate">
            Сформировать
          </button>
        </div>
      </div>
    </div>

    <div class="widget-wrapper quarter new" v-if="showReportID == 1">
      <div><h2>Запросы. Общая статистика за период</h2></div>
      <div>
        <div class="card">
          <div>
            <table>
              <thead>
                <th>Стутус</th>
                <th>Количество</th>
              </thead>
              <tbody>
                <tr v-for="state in requestStatData.states" :key="state.id">
                  <td>
                    <div
                      :class="{
                        'state-new-400': state.id == 1,
                        'state-warning-400': state.id == 2,
                        'state-error-400': state.id == 3,
                        'state-error': state.id == 5,
                        'state-total': state.id == -1,
                      }"
                    >
                      {{ state.title }}
                    </div>
                  </td>
                  <td :class="{ 'state-total': state.id == -1 }">
                    {{ state.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="widget-wrapper new" v-if="showReportID == 2">
      <div><h2>Запросы. Статистика по логистам за период</h2></div>
      <div>
        <div class="card">
          <div>
            <table>
              <thead>
                <th>Логист</th>
                <th
                  v-for="state in requestStatDataLogists.states"
                  :key="state.id"
                >
                  {{ state.title }}
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="logist in requestStatDataLogists.logists"
                  :key="logist.id"
                  @click="showRequestForLogist(logist)"
                >
                  <td class="center">
                    {{ logist.title }}
                  </td>
                  <td
                    v-for="state in requestStatDataLogists.states"
                    :key="state.id"
                  >
                    {{ logist["state" + state.id] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="widget-wrapper new" v-if="requestList_visible">
      <requests-list :list="requestList" @onClick="selectRequest" />
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import RequestsList from "@/components/orders/RequestsList.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";
import "@vuepic/vue-datepicker/dist/main.css";
import { convertBoolToInt } from "@/assets/js/lib.js";

export default {
  nmae: "RequestReportsView",
  components: {
    VueDatePicker,
    RequestsList,
  },

  data: () => ({
    date: null,
    dateFrom: null,
    dateTo: null,
    reporttypes: [
      { id: 1, title: "Статистика по запросам за период" },
      { id: 2, title: "Статистика по логистам за период" },
    ],
    reporttypeID: 0,
    showReportID: 0,
    includeFired: false,

    requestStatData: {
      states: [],
    },

    requestStatDataLogists: {
      states: [],
      logists: [],
    },

    requestList: [],
    requestList_visible: false,

    UIblock: false,
  }),

  mounted() {
    console.log("origin", document.location.origin);
  },

  methods: {
    onCreate() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      console.log(1, this.dateFrom, this.dateTo);

      this.UIblock = true;
      if (this.dateFrom == null) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать начальную дату периода!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      if (this.dateTo == null) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать конечную дату периода!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      if (this.reporttypeID == 0) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать отчет!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      this.requestList_visible = false;

      if (this.reporttypeID == 1) {
        this.CreateRequestStatisticReport();
      }

      if (this.reporttypeID == 2) {
        this.CreateRequestStatisticByLogistReport();
      }
      this.UIblock = false;
    },

    async CreateRequestStatisticReport() {
      let filter = utf8_to_b64(
        JSON.stringify({ from: this.dateFrom, to: this.dateTo })
      );
      let result = await this.$api.order.getRequestsStatisticByFilter(filter);
      if (result.statistic) {
        this.requestStatData = result.statistic;
        this.showReportID = 1;
      }
    },

    async CreateRequestStatisticByLogistReport() {
      let filter = utf8_to_b64(
        JSON.stringify({
          from: this.dateFrom,
          to: this.dateTo,
          includeFired: convertBoolToInt(this.includeFired),
        })
      );
      let result = await this.$api.order.getRequestsStatisticByLogistFilter(
        filter
      );
      if (result.statistic) {
        this.requestStatDataLogists = result.statistic;
        this.showReportID = 2;
        console.log(this.requestStatDataLogists);
      }
    },

    async showRequestForLogist(logist) {
      this.requestList_visible = false;
      const filter = utf8_to_b64(
        JSON.stringify({
          mode: "all",
          logistID: logist.id,
          from: this.date[0],
          to: this.date[1],
        })
      );
      const result = await this.$api.order.getRequests(filter);
      if (result.list) {
        this.requestList = result.list;
        this.requestList_visible = true;
      }
    },

    selectRequest(requestID) {
      console.log(
        document.location.origin + "?path=request/" + requestID,
        "requestWindow"
      );
      window.open(
        document.location.origin + "?path=request/" + requestID,
        "requestWindow"
      );
    },
  },
};
</script>

<style scoped>
.vdp-datepicker__calendar {
  z-index: 1000;
}
</style>
