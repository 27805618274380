<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Параметры пользователя</h1></div>
    </div>
    <div class="form card">
      <div class="label">Фамилия</div>
      <div class="half"><input type="text" v-model.trim="account.name1"></div>
      <div class="label">Имя</div>
      <div class="half"><input type="text" v-model.trim="account.name2"></div>
      <div class="label">Отчество</div>
      <div class="half"><input type="text" v-model.trim="account.name3"></div>
      <div class="label">Email</div>
      <div class="half"><input type="text" v-model.trim="account.email"></div>
      <div class="label">Телефон</div>
      <div class="half"><input type="text" v-model.trim="account.phone"></div>
      <div class="label">Пароль</div>
      <div class="half"><input type="password" v-model.trim="account.password"></div>
      <div class="label">Повторите пароль</div>
      <div class="half"><input type="password" v-model.trim="account.repassword"></div>
      <div class="label">Роли</div>
      <div class="row" 
        v-for="(item, index) in roles" :key="item.id"
      >
        <div class=" m-right-8"><input type="checkbox" v-model="roles[index].checked"></div>
        <div class="v-center m-right-32">{{ item.title }}</div>
        <div v-if="(item.id == 7)&&(roles[index].checked)" class="v-center">
          <div  class="row" 
            v-for="depart in tdepartment" :key="depart.id"
          >
            <div class="m-right-8"><input type="radio" v-model="account.departmentID" name="department" :value="depart.id"></div>
            <div class="v-center m-right-16">{{ depart.title }}</div>
          </div>
        </div>
      </div>
    </div>


    <div class="row new">
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="save()">Сохранить</button>
      </div>

      <div class="button m-left" v-if="account.id > 0">
        <button class="white bg-red-dark">Заблокировать</button>
      </div>

    </div>

  </div>
</template>

<script>
import { utf8_to_b64 } from '@/assets/js/base64.js';

export default {
  name: 'AccountPropsView',

  data: () => ({

    account: {
      id: 0,
      name1: '',
      name2: '',
      name3: '',
      email: '',
      phone: '',
      password: '',
      repassword: '',
      departmentID: 0
    },
    roles: [],
    tdepartment: [],
  }),

  async mounted() {
    this.loadRoles()
    this.loadTransportDepartment()
    if (this.$route.params&&(this.$route.params['id'] != '0')) {
      this.account.id = this.$route.params['id']
      this.load()
      return
    }

  },

  methods : {
    async loadRoles () {
      this.roles = []
      const result = await this.$api.auth.getRoles()
      if (result.roles) {
        result.roles.forEach(element => {
          element.checked = false
          this.roles.push(element);
        })
      }
    },

    async loadTransportDepartment () {
      this.tdepartment = []
      const result = await this.$api.company.getTransportDepartment()
      if (result.departments) {
        result.departments.forEach((element, index) => {
          element.checked = (index==0) ? true : false
          this.tdepartment.push(element);
        })
        if (this.tdepartment.length > 0) {
          this.account.departmentID = this.tdepartment[0].id
        }

      }
    },

    async load () {
      const result = await this.$api.auth.getAccount2(this.account.id)
      if (result.data) {
        this.account = result.data
        this.account.password = ''
        this.account.repassword = ''
        this.roles.forEach((element, index) => {
          if (result.data.roles.indexOf(element.id) >= 0) {
            this.roles[index].checked = true
          }
        })
        
      }
    },

    async save () {
      if (this.account.name1 == '') {
        this.$api.eventBus.emit('toast', {text: 'Фамилия не может быть пустой!', style: 'error'})
        return
      }

      if (this.account.email == '') {
        this.$api.eventBus.emit('toast', {text: 'Email не может быть пустым!', style: 'error'})
        return
      }

      if ((this.account.password == '')&&(this.account.id == '0')) {
        this.$api.eventBus.emit('toast', {text: 'Пароль не может быть пустым!', style: 'error'})
        return
      }

      if (this.account.password != this.account.repassword) {
        this.$api.eventBus.emit('toast', {text: 'Пароли не совпадают!', style: 'error'})
        return
      }

      let roles = []
      this.roles.forEach((element) => {
        if (element.checked) {
          roles.push(element.id)
        }
      })
      if (roles.length == 0) {
        this.$api.eventBus.emit('toast', {text: 'Необходимо выбрать хотя бы одну роль!', style: 'error'})
        return
      }

      let account = {}
      account.id = this.account.id
      account.name1 = utf8_to_b64(this.account.name1)
      account.name2 = utf8_to_b64(this.account.name2)
      account.name3 = utf8_to_b64(this.account.name3)
      account.email = utf8_to_b64(this.account.email)
      account.phone = utf8_to_b64(this.account.phone)
      account.password = utf8_to_b64(this.account.password)
      account.departmentID = this.account.departmentID
      account.roles = roles

      let result
      if (this.account.id == '0') {
        result = await this.$api.auth.createAccount2(account)
      }
      else {
        result = await this.$api.auth.updateAccount2(account)
      }

      if (result.ok) {
        this.$router.push('/accounts')
      }
    }
  }
}
</script>

<style>

</style>