<template>
  <popup-modal ref="popup">
    <h2 style="margin-top:0;">{{ title }}</h2>
    <p v-html="message"></p>
    <div class="row button meduim" style="margin-top: 16px;">
      <div class="m-left m-right-8">
        <button class="white bg-prime-prime-blue medium" @click.prevent="_confirm">{{ okButton }}</button>
      </div>
      <div>
        <button class="white bg-prime-prime-blue medium" @click="_cancel">{{ cancelButton }}</button>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/app/PopupModal.vue'

export default {
  name: 'ConfirmDlg',
  components : {
    PopupModal
  },
  data: () => ({
    title: undefined,
    message: undefined, // Main text content
    okButton: 'Войти',
    cancelButton: 'Отмена', // text for cancel button

    
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
        this.title = opts.title;
        this.message = opts.message
        this.okButton = opts.okButton
        this.$refs.popup.open()
        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
            this.resolvePromise = resolve
            this.rejectPromise = reject
        })
    },

    async _confirm() {
      this.$refs.popup.close()
      this.resolvePromise({accept : true})
    },

    _cancel() {
        this.$refs.popup.close()
        this.resolvePromise({accept : false})
    },
  }
}
</script>

<style>

</style>