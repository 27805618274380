<template>
  <div class="card new">
    <div v-if="contacts.length == 0">Контакты не заданы</div>

    <table-box v-else :header="tableHeader" :body="tableBody" @edit="onEdit" />
  </div>
  <div class="row new">
    <div class="button small">
      <button class="white bg-prime-prime-blue" @click="onCreate">
        Дабавить контакт
      </button>
    </div>
  </div>

  <contact-dlg ref="contactsDlg" />
</template>

<script>
import TableBox from "@/components/app/ui/TableBox.vue";
import { getStatusIcon } from "@/assets/js/icons.js";
import ContactDlg from "@/components/modals/company/ContactDlg.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";
export default {
  name: "ContactsList",

  components: {
    TableBox,
    ContactDlg,
  },

  props: {
    contragentID: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      { id: 3, title: "Тип", width: "15%", align: "left" },
      { id: 2, title: "Контакт", width: "30%", align: "left" },
      { id: 4, title: "Значение", width: "40%", align: "left" },
      { id: 5, title: "Статус", width: "10%", align: "center" },
    ],
    tableBody: [],

    contacts: [],
    contactTypes: [],
  }),

  mounted() {
    this.loadTypes();
    this.load();
  },

  methods: {
    async loadTypes() {
      const result = await this.$api.contragetns.getContactTypes();
      if (result.list) {
        this.contactTypes = result.list;
      }
    },

    async load() {
      if (this.contragentID > 0) {
        const result = await this.$api.contragetns.getContacts(
          this.contragentID
        );
        this.tableBody = [];
        if (result.list) {
          this.contacts = result.list;
          this.contacts.forEach((element) => {
            this.tableBody.push({
              id: element.id,
              row: [
                { id: 0, value: element.id },
                {
                  id: 1,
                  value: `${element.icon}&nbsp;&nbsp;${element.typetitle}`,
                },
                { id: 2, value: element.title },
                { id: 3, value: element.value },
                { id: 4, value: getStatusIcon(element.status) },
              ],
            });
          });
        }
      }
    },

    async onCreate() {
      let result = await this.$refs.contactsDlg.show({
        value: {
          id: 0,
          title: "",
          typeID: 0,
          value: "",
        },
        contactTypes: this.contactTypes,
        okButton: "Создать",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.getUpdateContact({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
      }
    },

    async onEdit(cell) {
      if (cell.col == 4) {
        const result = await this.$api.contragetns.invertContactStatus(
          this.tableBody[cell.row].id
        );
        if (result.ok) {
          this.load();
        }

        return;
      }

      let result = await this.$refs.contactsDlg.show({
        value: this.contacts[cell.row],
        contactTypes: this.contactTypes,
        okButton: "Сохранить",
      });

      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.getUpdateContact({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
      }
    },
  },
};
</script>

<style></style>
