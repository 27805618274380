<template>
    <popup-modal ref="popup">
      <div style="width: 1000px;">
        <h2 style="margin-top:0;">{{ title }}</h2>

        <files-upload-box
          ref="filesUploadBox"
          @onChange="(size) => {filesLength = size;}"
        />

        <div class="row button meduim" style="margin-top: 16px;">
          <div class="m-left m-right-8">
            <button class="white bg-prime-prime-blue medium"  v-on:click="submitFiles()" v-show="filesLength > 0">Загрузить</button>
          </div>
          <div><button class="white bg-prime-prime-blue medium" @click="_cancel">Отмена</button></div>
        </div>
      </div>
    </popup-modal>
</template>

<script>
import PopupModal from '@/components/app/PopupModal.vue'
import FilesUploadBox from '@/components/app/ui/FilesUploadBox.vue'

export default {
  name: 'FilesUpload.Dlg',
  components : {
    PopupModal, FilesUploadBox
  },

  props: {
    'title' : {
      type : String,
      default : 'Загрузка файлов'
    },
    'module' : String,
    'sourceID' : Number,
  },

  data: () => ({
    dragAndDropCapable: false,
    filesLength: 0,
    uploadPercentage: 0,
    showProgress: false,

    resolvePromise: null,
    rejectPromise: null,
  }),

  methods : {
    show() {

      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
      })
    },

    async _confirm() {
      this.$refs.popup.close()
      this.resolvePromise({ok : true})
    },

    _cancel() {
        this.$refs.popup.close()
        this.resolvePromise({ok : false})
    },

    async submitFiles() {
      const ok = await this.$refs.filesUploadBox.uploadFiles(this.module, this.sourceID)
      if (ok) {
        this.$refs.popup.close()
        this.resolvePromise({ok : true})
      }

    }
  }
}
</script>

<style>

</style>