export default function (instance) {
  return {

    async createcars_services(data) {
      return await this.post('cars/services/create', data)
    },

    async searchDriver(data) {
      return await instance.get(`drivers/search/${data.executorID}/${data.searchStr}`)
    },

  }
}