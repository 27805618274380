export function convertBoolToInt(bool) {
  return bool ? 1 : 0;
}

export function convertIntToB(int) {
  return int == 1 ? true : false;
}

export function sortUPbyField(fieldName) {
  // Сортировка массива объектов по полю по возрастанию
  return (a, b) => (a[fieldName] > b[fieldName] ? 1 : -1);
}

export function sortDOWNbyField(fieldName) {
  // Сортировка массива объектов по полю по возрастанию
  return (a, b) => (a[fieldName] < b[fieldName] ? 1 : -1);
}

export function getContragentShortName(contragent) {
  // выдает короткое название контрагшента
  if (contragent) {
    if (contragent.comptypeID == 0) {
      // частное лицо
      return `${contragent.name1} ${contragent.name2} ${contragent.name2}`;
    } else {
      return `${contragent.form.short} ${contragent.name2}`;
    }
  }
  return "";
}
