<template>
  <div :class="{'cursor' : !disabled}" @click="onClick">
    <svg v-if="!value" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#323430" stroke-opacity="0.1" :fill="'white'"/>
    </svg>

    <svg  v-if="value" width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="#326698" />
      <path d="M20.455 11.455C20.6663 11.2538 20.9475 11.1426 21.2392 11.1448C21.531 11.147 21.8104 11.2625 22.0187 11.4668C22.2269 11.6712 22.3476 11.9484 22.3553 12.2401C22.363 12.5317 22.2571 12.815 22.06 13.03L16.075 20.515C15.9721 20.6259 15.8479 20.7148 15.7098 20.7766C15.5717 20.8383 15.4226 20.8716 15.2714 20.8744C15.1201 20.8772 14.9699 20.8495 14.8296 20.7929C14.6894 20.7363 14.5619 20.652 14.455 20.545L10.486 16.576C10.3755 16.473 10.2868 16.3488 10.2253 16.2108C10.1638 16.0728 10.1308 15.9238 10.1281 15.7728C10.1254 15.6217 10.1532 15.4717 10.2098 15.3316C10.2664 15.1915 10.3506 15.0643 10.4574 14.9575C10.5643 14.8506 10.6915 14.7664 10.8316 14.7098C10.9717 14.6532 11.1217 14.6255 11.2728 14.6281C11.4238 14.6308 11.5728 14.6639 11.7108 14.7253C11.8488 14.7868 11.973 14.8755 12.076 14.986L15.217 18.1255L20.4265 11.488C20.4359 11.4765 20.4444 11.4654 20.455 11.455Z" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#326698" />
    </svg>

  </div>
</template>

<script>
export default {
  name: 'CheckBox',

  props : {
    'initvalue' : {
      type: Boolean,
      default: false
    },
    'disabled' : {
      type: Boolean,
      default: false
    },
  },

  emits: [
    'onChange'
  ],

  watch: {
    initvalue(newQuestion) {
      this.value = newQuestion
    }
  },

  data : () => ({
    value : false
  }),

  mounted () {
    this.value = this.initvalue
  },

  methods: {
    onClick () {
      if (!this.disabled) {
        this.value = !this.value; 
        this.$emit('onChange', this.value);
      }

    }
  }
}
</script>

<style>

</style>