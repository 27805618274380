<template>
<div class="quarter-3">
  <div class="row-center">
    <div><h1>Пользователи</h1></div>
    <div class="button m-left">
      <button class="white bg-prime-prime-blue" @click="$router.push('account/0')">добавить</button>
    </div>
  </div>
  <div class="card new">
    <div>
      <table>
        <thead>
          <th>#</th>
          <th>ID</th>
          <th>ФИО</th>
          <th>Email</th>
          <th>Роли</th>
          <th>Статус</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in staffArray" :key="item.id" @click="edit(item)">
            <td>{{ index+1 }}</td>
            <td>{{ item.id }}</td>
            <td>{{ item.name1 }} {{ item.name2 }} {{ item.name3 }}</td>
            <td>{{ item.email }}</td>
            <td>
              {{ item.rolesTitle }}
              <span v-if="item.department != ''">({{ item.department }})</span>
            </td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--
    <div>
      <div class="table-pages row">
        <div>Строки с 1 по 1 из 1</div>
        <div class="row m-left">
          <div class="table-pages__page link">	&lt;</div>
          <div class="table-pages__page link">1</div>
          <div class="table-pages__page this">1</div>
          <div class="table-pages__page">...</div>
          <div class="table-pages__page link">1</div>
          <div class="table-pages__page link">1</div>
          <div class="table-pages__page link">&gt;</div>
        </div>
      </div>
    </div>
  -->
  </div>
</div>
</template>

<script>
export default {
  name: 'AccountsView',

  mounted () {
    this.loadStaff()
  },

  data: () => ({
    staffArray: []
  }),

  methods: {
    async loadStaff() {
      const result = await this.$api.auth.getAccounts('all')
      if (result.accounts) {
        this.staffArray = result.accounts
        console.log(result.accounts);
        return
      }
    },

    edit(item) {
      this.$router.push('account/'+item.id)
    }

  }
}
</script>

<style>

</style>