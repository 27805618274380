<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Адрес</h2>

    <div class="wrap">
      <div style="margin-top: 20px">
        <div class="card new" v-if="existList.length > 0">
          <div class="label row-center">
            <input
              type="radio"
              name="addressType"
              class="input__radio"
              id="type1"
              v-model="typeAddress"
              value="1"
            /><label for="type1" class="input__label">Адреса из маршрута</label>
          </div>
          <div v-if="typeAddress == 1">
            <select size="4" v-model="existAddressID" class="select">
              <option v-for="item in existList" :key="item.id" :value="item.id">
                {{ item.addressline }}
              </option>
            </select>
          </div>
        </div>
        <div class="card new">
          <div class="label row-center">
            <input
              type="radio"
              name="addressType"
              class="input__radio"
              id="type2"
              v-model="typeAddress"
              value="2"
            /><label for="type2" class="input__label"
              >Поиск адреса в базе данных</label
            >
          </div>

          <div v-if="typeAddress == 2">
            <div style="margin-top: 20px">
              <input-control :initvalue="initValue" @accept="search" />
            </div>
            <div style="margin-top: 20px">
              <select
                class="select"
                size="5"
                v-model="resultIndex"
                :disabled="!ready"
              >
                <option
                  v-for="(item, index) in resultList"
                  :key="item.id"
                  :value="index"
                >
                  {{ item.addressline }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card new">
          <div class="label row-center">
            <input
              type="radio"
              name="addressType"
              class="input__radio"
              id="type3"
              v-model="typeAddress"
              value="3"
            /><label for="type3" class="input__label"
              >Задвноме нового адреса</label
            >
          </div>
          <div v-if="typeAddress == 3">
            <div class="row-center" style="margin-top: 20px; gap: 8px">
              <div style="width: 150px">
                <div class="label">Индекс</div>
                <input type="text" v-model="address.postcode" />
              </div>
              <div>
                <div class="label">Субьект Федерации</div>
                <input type="text" v-model="address.provinces" />
              </div>
              <div>
                <div class="label">Населенны пункт</div>
                <input type="text" v-model="address.town" />
              </div>
            </div>
            <div style="margin-top: 8px">
              <div class="label">улица, дом и пр</div>
              <input type="text" v-model="address.address" />
            </div>
            <div style="margin-top: 8px">
              <div class="label">
                Комментарий к адресу (будет всегда показываться у данного
                адреса)
              </div>
              <textarea v-model="address.comment"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="card new">
        <h2>
          Выбран адрес
          <span v-if="selectesAddressID > 0">#{{ selectesAddressID }}</span>
          <span v-else>(новый)</span>:
        </h2>
        <span class="selected-address" v-if="selectesAddress != ''">
          {{ selectesAddress }}
        </span>
        <span class="unselected-address" v-else>Адрес не задан</span>
      </div>

      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
            v-if="selectesAddress != ''"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import InputControl from "@/components/app/ui/InputControl.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "FindAddressDlg",

  components: {
    PopupModal,
    InputControl,
  },
  data: () => ({
    typeAddress: 1, // тип адреса - ранее заданных, поиск, вручную
    // Блок выбора из ранее заданных
    existList: [], // список ранее введеных адресов
    existAddressID: 0, // ID выбранного адреса

    // Раздел поиска адреса по базе данных
    initValue: "",
    ready: false, // Признак того, что данные поиска получена
    resultList: [], // Список найденных данных
    resultIndex: -1, // Индексс выбранного значения и списка

    // Раздел задания нового адрема
    address: {},

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  computed: {
    selectesAddressID() {
      if (this.typeAddress == 1) return this.existAddressID;
      if (this.typeAddress == 2) {
        return this.resultIndex > -1 ? this.resultList[this.resultIndex].id : 0;
      }
      if (this.typeAddress == 3) return this.address.id;
      return 0;
    },

    selectesAddress() {
      if (this.typeAddress == 1) {
        if (this.existAddressID > 0) {
          return this.existList.find((el) => el.id === this.existAddressID)
            .addressline;
        }
      }
      if (this.typeAddress == 2) {
        if (this.resultIndex > -1) {
          return this.getAddressLine(this.resultList[this.resultIndex]);
        }
      }
      if (this.typeAddress == 3) {
        return this.getAddressLine(this.address);
      }

      return "";
    },
  },

  mounted() {
    this.clearAddressCard();
    this.loadOrderTypes();
  },

  methods: {
    async loadOrderTypes() {
      const result = await this.$api.order.getRouteTypes();
      if (result.list) {
        this.routeTypes = result.list;
      }
    },

    show(opts = {}) {
      this.clearAddressCard();
      this.existList = opts.existList ? opts.existList : [];
      this.existAddressID = 0;
      // Если еще нет списка ранее введенных адресов
      this.typeAddress = this.existList.length == 0 ? 2 : 1;

      // поиск в базеданных
      this.resultIndex = -1;
      this.resultList = [];
      this.initValue = "";

      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      let value = {};
      if (this.typeAddress == 1) {
        value = { id: this.existAddressID };
      }
      if (this.typeAddress == 2) {
        value = this.resultList[this.resultIndex];
      }
      if (this.typeAddress == 3) {
        value = this.address;
      }
      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },

    async clearAddressCard() {
      this.address = {
        id: 0,
        postcode: "111396",
        provinces: "",
        town: "Москва",
        address: "Зеленый пр, 60",
        comment: "код 3",
      };
    },

    async search(data) {
      if (data.value == "") {
        return;
      }
      this.ready = false;
      this.resultList = [{ id: 1, addressline: "Идет поиск...." }];
      this.resultIndex = -1;

      const result = await this.$api.address.search(utf8_to_b64(data.value));
      console.log(result);
      if (result.list && result.list.length > 0) {
        this.resultList = result.list;
        this.ready = true;
        return;
      }
      this.resultList = [
        { id: 1, addressline: "По Вашему запросу ничего не найдено." },
      ];
    },

    getAddressLine(address) {
      let result = [];
      if (address.postcode != "") {
        result.push(address.postcode);
      }
      if (address.provinces != "") {
        result.push(address.provinces);
      }
      if (address.town != "") {
        result.push(address.town);
      }
      if (address.address != "") {
        result.push(address.address);
      }
      return result.length > 0 ? result.join(", ") : "";
    },
  },
};
</script>

<style scoped>
.wrap {
  width: 600px;
}

.select {
  width: 100%;
  height: 150px;
}

.unselected-address {
  font-size: 0.8em;
  font-style: italic;
}
</style>
