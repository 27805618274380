<template>
  <div class="card new">
    <div v-if="bankDetals.length == 0">Банковские реквизите не заданы</div>

    <table-box v-else :header="tableHeader" :body="tableBody" @edit="onEdit" />
  </div>
  <div class="row new">
    <div class="button small">
      <button class="white bg-prime-prime-blue" @click="onCreate">
        Создать реквизиты
      </button>
    </div>
  </div>

  <bank-details-dlg ref="bankDetailsDlg" />
</template>

<script>
import BankDetailsDlg from "@/components/modals/company/BankDetailsDlg.vue";
import { getStatusIcon } from "@/assets/js/icons.js";
import TableBox from "@/components/app/ui/TableBox.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "BankDetails",

  components: {
    BankDetailsDlg,
    TableBox,
  },

  props: {
    contragentID: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      { id: 3, title: "БИК", width: "10%", align: "center" },
      { id: 2, title: "Наименование счета", width: "70%", align: "left" },
      { id: 2, title: "Статус", width: "10%", align: "center" },
    ],
    tableBody: [],

    bankDetals: [],
  }),

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      if (this.contragentID > 0) {
        const result = await this.$api.contragetns.getBankDetails(
          this.contragentID
        );
        this.tableBody = [];
        if (result.list) {
          this.bankDetals = result.list;
          this.bankDetals.forEach((element) => {
            this.tableBody.push({
              id: element.id,
              row: [
                { id: 0, value: element.id },
                { id: 1, value: element.bank.bik },
                { id: 2, value: element.title },
                { id: 2, value: getStatusIcon(element.status) },
              ],
            });
          });
        }
      }
    },

    async onCreate() {
      let result = await this.$refs.bankDetailsDlg.show({
        value: {
          id: 0,
          title: "",
          bank: {
            bik: "",
            title: "",
            korr: "",
            inn: "",
            address: "",
          },
          accountnumber: "",
          accountname: "",
        },
        okButton: "Создать",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updateBankDetails({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
        // const query = "044525225"
        //
      }
    },

    async onEdit(cell) {
      console.log(cell);
      if (cell.col == 3) {
        const result = await this.$api.contragetns.invertBankStatus(
          this.tableBody[cell.row].id
        );
        if (result.ok) {
          this.load();
        }

        return;
      }
      let result = await this.$refs.bankDetailsDlg.show({
        value: this.bankDetals[cell.row],
        okButton: "Сохранить",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updateBankDetails({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
        // const query = "044525225"
        //
      }
    },
  },
};
</script>

<style></style>
