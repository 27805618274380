<template>
  <div>
    <table>
      <thead>
        <th>#</th>
        <th>
          Дата
          <div class="table-sort" @click="sortList(rows, 0, 'date')">
            &#8597;
          </div>
        </th>
        <th>
          Время
          <div class="table-sort" @click="sortList(rows, 1, 'time')">
            &#8597;
          </div>
        </th>
        <th>
          Заказчик
          <div class="table-sort" @click="sortList(rows, 2, 'customer')">
            &#8597;
          </div>
        </th>
        <th>
          Логист
          <div class="table-sort" @click="sortList(rows, 3, 'logist')">
            &#8597;
          </div>
        </th>
        <th>
          Отдел
          <div class="table-sort" @click="sortList(rows, 4, 'department')">
            &#8597;
          </div>
        </th>
        <th>
          <div class="table-sort" @click="sortList(rows, 5, 'stateTitle')">
            &#8597;
          </div>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="item in rows"
          :key="item.id"
          class="cursor"
          @click="$emit('onClick', item.id)"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.customer }}</td>
          <td>{{ item.logist }}</td>
          <td>{{ item.department }}</td>
          <td>
            <div
              :class="{
                'state-new': item.state == 1,
                'state-error-400': item.state == 3,
                'state-error': item.state == 5,
              }"
            >
              {{ item.stateTitle }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { sortUPbyField, sortDOWNbyField } from "@/assets/js/lib";
export default {
  name: "OrdersList",
  props: {
    list: Array,
  },
  emits: ["onClick"],

  data: () => ({
    rows: [],
    sort: ["", "", "", "", "", ""],
  }),

  watch: {
    list: {
      handler(newList) {
        this.rows = newList;
      },
      deep: true,
    },
  },

  mounted() {
    this.rows = this.list;
  },

  methods: {
    sortList(list, colIndex, fieldName) {
      if (this.sort[colIndex] == "") {
        list.sort(sortUPbyField(fieldName));
        this.sort[colIndex] = "up";
        return;
      }
      if (this.sort[colIndex] == "up") {
        list.sort(sortDOWNbyField(fieldName));
        this.sort[colIndex] = "down";
        return;
      }
      if (this.sort[colIndex] == "down") {
        list.sort(sortUPbyField("id"));
        this.sort[colIndex] = "";
        return;
      }
    },
  },
};
</script>

<style></style>
