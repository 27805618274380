<template>
  <div class="card new">
    <div v-if="EmploymentHistory.length == 0">Записей нет</div>

    <table-box v-else :header="tableHeader" :body="tableBody" @edit="onEdit" />
  </div>
  <div class="row new">
    <div class="button small">
      <button class="white bg-prime-prime-blue" @click="onCreate">
        Создать запись
      </button>
    </div>
  </div>

  <employment-history-dlg ref="employmenthistoryDlg" />
</template>

<script>
import EmploymentHistoryDlg from "@/components/modals/company/EmploymentHistoryDlg.vue";
import TableBox from "@/components/app/ui/TableBox.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "EmploymentHistory",

  components: {
    EmploymentHistoryDlg,
    TableBox,
  },

  props: {
    contragentID: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      { id: 2, title: "Дата приема", width: "10%", align: "left" },
      { id: 3, title: "Дата увольнения", width: "10%", align: "left" },
      { id: 4, title: "Подразделение", width: "20%", align: "left" },
      { id: 5, title: "Должность", width: "50%", align: "left" },
    ],
    tableBody: [],

    EmploymentHistory: [],
  }),

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      if (this.contragentID > 0) {
        const result = await this.$api.contragetns.getEmploymentHistory(
          this.contragentID
        );
        this.tableBody = [];
        if (result.list) {
          this.EmploymentHistory = result.list;
          this.EmploymentHistory.forEach((element) => {
            this.tableBody.push({
              id: element.id,
              row: [
                { id: 0, value: element.id },
                { id: 1, value: element.date_start },
                { id: 2, value: element.date_end },
                { id: 3, value: element.department },
                { id: 4, value: element.position },
              ],
            });
          });
        }
      }
    },

    async onCreate() {
      let result = await this.$refs.employmenthistoryDlg.show({
        value: {
          id: 0,
          date_start: "1",
          date_end: "2",
          departmentID: 0,
          positionID: 0,
        },
        okButton: "Создать",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updateEmploymentHistory({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          const employmentHistoryID = result2.id;
          let result3 = await this.$refs.employmenthistoryDlg.uploadFiles(
            employmentHistoryID
          );
          if (result3) {
            this.load();
          }
        }
      }
    },

    async onEdit(cell) {
      let result = await this.$refs.employmenthistoryDlg.show({
        value: this.EmploymentHistory[cell.row],
        okButton: "Сохранить",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updateEmploymentHistory({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
      }
    },
  },
};
</script>

<style></style>
