<template>
  <div class="label">
    <h4>{{ title }}</h4>
  </div>
  <div class="row-center" v-for="file in files" :key="file.id">
    <div class="m-right-8"><i class="fa fa-file"></i></div>
    <div>{{ file.filename }}</div>
    <div class="m-left m-right-8 button small">
      <button class="white bg-prime-prime-blue small" @click="onDowoload(file)">
        Скачать
      </button>
    </div>
    <div class="brown cursor" @click="onDelete(file)" v-if="!readonly">
      <i class="fa fa-trash"></i>
    </div>
  </div>

  <div v-if="files.length == 0">Список пуст</div>
  <div class="row new" v-if="!readonly">
    <div class="m-left button medium">
      <button class="white bg-prime-prime-blue medium" @click="onFileUpload">
        Загрузить файл
      </button>
    </div>
  </div>

  <files-upload-dlg
    ref="filesUploadDlg"
    :module="module"
    :sourceID="sourceID"
  />

  <ConfirmDlg ref="confirmDelete" />
</template>

<script>
import ConfirmDlg from "@/components/modals/app/ConfirmDlg.vue";
import FilesUploadDlg from "@/components/modals/app/FilesUploadDlg.vue";
export default {
  name: "FilesList",
  components: {
    ConfirmDlg,
    FilesUploadDlg,
  },

  props: {
    title: String,
    module: String,
    sourceID: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    files: [],
  }),

  watch: {
    sourceID(newValue) {
      if (newValue > 0) {
        this.loadFilesList();
      }
    },
  },

  mounted() {
    if (this.sourceID > 0) {
      this.loadFilesList();
    }
  },

  methods: {
    async loadFilesList() {
      const result = await this.$api.files.getFiles(this.module, this.sourceID);
      if (result.list) {
        this.files = result.list;
      }
    },

    async onDelete(file) {
      const ok = await this.$refs.confirmDelete.show({
        title: "Подтвердите свое действие",
        message: `Вы точно хотите удалить ${file.filename}?`,
        okButton: "Удалить",
      });
      if (ok.accept) {
        const result = await this.$api.files.deleteFiles(
          this.module,
          this.sourceID,
          file.fid
        );
        if (result.ok) {
          this.loadFilesList();
        }
      }
    },

    async onFileUpload() {
      const ok = await this.$refs.filesUploadDlg.show();
      if (ok) {
        this.loadFilesList();
      }
    },

    onDowoload(file) {
      console.log(file);
      console.log(this.$api.files.getFileURL(file));
      window.open(this.$api.files.getFileURL(file));
    },
  },
};
</script>

<style></style>
