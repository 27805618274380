<template>
  <div id="file-drag-drop" class="file-upload-box">
    <form ref="fileform">
      <div class="file-drag-drop__wrap" v-on:click="addFiles()">
        <span class="drop-files cursor"
          >Перетащите сюда файлы или нажмите, чтобы загрузить</span
        >
      </div>
      <div class="files-preview-wrap">
        <div v-for="(file, key) in files" :key="key" class="file-preview">
          <div class="file-preview__remove">
            <i class="fas fa-times" v-on:click="removeFile(key)"></i>
          </div>
          <div>
            <img class="preview" v-bind:ref="'image' + parseInt(key)" />
          </div>
          <div class="file-preview__filename">
            {{ file.name }}
          </div>
        </div>
      </div>
      <input
        type="file"
        id="files"
        ref="files"
        accept="image/*"
        class="none"
        multiple
        v-on:change="handleFilesUpload()"
      />
    </form>
  </div>
  <div class="file-upload-tools">
    <div class="file-upload-tools__progress-bar" style="max-width: 100%">
      <div v-if="showProgress">
        <progress max="100" :value.prop="uploadPercentage"></progress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilesUploadBox",

  emits: ["onChange"],

  data: () => ({
    module: "",
    sourceID: 0,
    dragAndDropCapable: false,
    files: [],
    uploadPercentage: 0,
    showProgress: false,
  }),

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
          this.getImagePreviews();
          this.$emit("onChange", this.files.length);
        }.bind(this)
      );
    }
  },

  methods: {
    determineDragAndDropCapable() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    removeFile(key) {
      this.files.splice(key, 1);
      this.$emit("onChange", this.files.length);
    },

    async submitFiles() {
      this.showProgress = true;
      let formData = new FormData();

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append("files[" + i + "]", file);
      }

      const response = await this.$api.files.sendFiles(
        this.module,
        this.sourceID,
        formData,
        function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }.bind(this)
      );
      this.showProgress = false;
      if (response.ok) {
        return true;
      }
      return false;
    },

    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.getImagePreviews();
    },

    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        /*
          Ensure the file is an image file
        */
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
          /*
            Create a new FileReader object
          */
          let reader = new FileReader();

          /*
            Add an event listener for when the file has been loaded
            to update the src on the file preview.
          */
          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );
          /*
            Read the data for the file in through the reader. When it has
            been loaded, we listen to the event propagated and set the image
            src to what was loaded from the reader.
          */
          reader.readAsDataURL(this.files[i]);
        }
      }
    },

    addFiles() {
      this.$refs.files.click();
    },

    async uploadFiles(module, sourceID) {
      this.module = module;
      this.sourceID = sourceID;
      if (this.files.length > 0) {
        return await this.submitFiles();
      }

      return true;
    },
  },
};
</script>

<style></style>
