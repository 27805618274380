<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Сотрудники</h1></div>
    </div>
    <companies-list-view 
      :canAdd="access.canEdit"
      :canEdit="access.canEdit"
      :companyfilter="'company_staff'"
    />
  </div>
</template>

<script>
import CompaniesListView from '@/components/finance/CompaniesListView.vue'
export default {
  name : 'CompanyStaffView',
  components : {
    CompaniesListView
  },
  data: ()=> ({
    access : {
      canEdit : false
    }
  }),

  async mounted () {
    const result = await this.$api.auth.getAccessMode('company_staff')
    if (result.access) {
      this.access = result.access
    }
  },

}
</script>

<style>

</style>