<template>
  <popup-modal ref="popup">
    <div style="width: 640px;">
      <h2 style="margin-top:0;">{{ title }}</h2>

      <div style="margin-top: 20px">
        <select v-model="selectValue">
          <option
            v-for="item in list" :key="item.id" :value="item.id"
          >
            {{ item.title }}
          </option>
        </select>
      </div>

      <div style="margin-top: 20px">
        <input type="text" v-model="inputValue">
      </div>

      <div class="row button meduim" style="margin-top: 16px;">
        <div class="m-left m-right-8">
          <button class="white bg-prime-prime-blue medium" @click.prevent="_confirm">{{ okButton }}</button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">{{ cancelButton }}</button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/app/PopupModal.vue'

export default {
  name: 'SelectInputDlg',
  components : {
    PopupModal
  },
  data: () => ({
    title: undefined,
    selectValue: 0,
    inputValue: '',
    list: [],
    okButton: 'Войти',
    cancelButton: 'Отмена', // text for cancel button

    
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
        this.title = opts.title
        this.selectValue = opts.selectValue 
        this.inputValue = opts.inputValue 
        this.list = opts.list
        this.okButton = opts.okButton
        this.$refs.popup.open()
        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
            this.resolvePromise = resolve
            this.rejectPromise = reject
        })
    },

    async _confirm() {
      this.$refs.popup.close()
      this.resolvePromise({ok : true, select: this.selectValue, input: this.inputValue})
    },

    _cancel() {
        this.$refs.popup.close()
        this.resolvePromise({ok : false})
    },
  }
}
</script>

<style>

</style>