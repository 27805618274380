<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Уведомления</h1></div>
      <div class="button m-left">
        <button class="white bg-prime-prime-blue" @click="$router.push('notification/0')">создать</button>
      </div>
    </div>
    <div class="card new">
      <table>
        <thead>
          <th v-for="th in header" 
            :key="th.id"
            :width="th.width"
            class="w-center"
            
            >
            {{ th.title }}</th>
        </thead>
        <tbody class="cursor">
          <tr v-for="notification in notifications" 
            :key="notification.id"
            @click="onEdit(notification)"
            :class="{ 'bold' : notification.new }"
          >
            <td align="center">{{ notification.id }}</td>
            <td align="center">{{ notification.date }}</td>
            <td>{{ notification.sender }}</td>
            <td>{{ notification.subject }}</td>
          </tr>
        </tbody>
      </table>

      <div style="margin: 10px; font-style: italic;">Уведомлений нет</div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'NotificationsView',
  data: () => ({
    header: [
      {id: 2, title : 'ID', width: '5%', align:'center'},
      {id: 3, title : 'Дата', width: '10%', align:'left'},
      {id: 3, title : 'Отправитель', width: '20%', align:'left'},
      {id: 3, title : 'Тема', width: '60%', align:'left'},
    ],

    notifications : []
  }),

  mounted () {
    this.loadNotifications()
  },

  methods : {
    async loadNotifications() {
      const result = await this.$api.auth.getNotifications()
      if (result.list) {
        this.notifications = result.list
      }
    },

    onEdit(notification) {
      this.$router.push('notification/'+notification.id)
    }
  }

}
</script>

<style>

</style>