<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Словари</h1></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DictionariesView",

  data: () => ({}),

  async mounted() {},

  methods: {},
};
</script>

<style></style>
