<template>
  <table>
    <thead>
      <th v-for="th in header" 
        :key="th.id"
        :width="th.width"
        class="w-center"
        
        >
        {{ th.title }}</th>
    </thead>
    <tbody class="cursor">
      <tr v-for="(tr, row) in body" 
        :key="tr.id"
      >
        <td v-for="(td, col) in tr.row" 
          :key="td.id"
          :align="header[col].align"
          v-html="td.value"
          @click="$emit('edit', {row, col})"
        >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name : 'TableBox',
  props: {
    'header' : Array,
    'body' : Array
  },

}
</script>

<style>

</style>