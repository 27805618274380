<template>
  <div class="half">
    <div><h1>Новая заявка</h1></div>
    <div class="card new">
      <div class="input__radio-inner">Отдел</div>
      <div class="radio__row" style="margin-top: 8px">
        <div
          class="input__radio-inner"
          v-for="depart in tdepartment"
          :key="depart.id"
        >
          <input
            type="radio"
            name="department"
            class="input__radio"
            :id="'nation' + depart.id"
            v-model="order.departmentID"
            :value="depart.id"
          /><label :for="'nation' + depart.id" class="input__label">{{
            depart.title
          }}</label>
        </div>
      </div>

      <div v-if="!isLogist" style="margin-top: 16px">
        <div class="label">Ответственный логист</div>
        <div class="half" style="margin-top: 8px">
          <select v-model.trim="order.logistID">
            <option value="0"></option>
            <option
              v-for="logist in logists"
              :key="logist.id"
              :value="logist.id"
            >
              {{ logist.name1 }} {{ logist.name2 }} {{ logist.name3 }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="card new">
      <div class="input__radio-inner">Заказчик</div>
      <div class="row-center">
        <input
          type="text"
          class="cursor"
          v-model.trim="customerCard.name1"
          readonly
          @click="searchCustomer"
        />
        <svg
          class="cursor"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="searchCustomer"
        >
          <path
            d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
            fill="#313333"
            fill-opacity="0.6"
          />
        </svg>
      </div>
      <div>{{ customerText }}</div>

      <div class="input__radio-inner" style="margin-top: 8px">Ставка</div>
      <div>
        <paymant-box
          :placeholder="'Значение ставки'"
          :initvalue="order.payment.debet"
          :initTax="order.payment.taxID"
          @updated="paymantUpdate"
        />
      </div>

      <div class="label" style="margin-top: 8px">Комментарий к заявке</div>
      <div>
        <div>
          <textarea v-model.trim="order.content"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="row new">
    <div class="button">
      <button class="white bg-prime-prime-blue" @click="onSave">Создать</button>
    </div>
  </div>

  <search-easy-dlg ref="searcheasyDlg" />
</template>

<script>
import { utf8_to_b64 } from "@/assets/js/base64.js";
import SearchEasyDlg from "@/components/modals/app/SearchEasyDlg.vue";
import PaymantBox from "@/components/finance/PaymantBox.vue";

export default {
  name: "OrderNewView",

  components: {
    SearchEasyDlg,
    PaymantBox,
  },

  data: () => ({
    order: {
      id: 0,
      departmentID: 3,
      logistID: 0,
      customerID: 0,
      content: "",
      paymentID: 0,
      payment: {
        debet: 0,
        taxID: 1,
      },
      state: 0,
    },
    customerText: "",
    customerCard: {
      id: 0,
      name1: "",
    },

    stateID: 10,
    orderStates: [],
    tdepartment: [],
    logists: [],
    UIblock: false,
  }),

  computed: {
    isSuper() {
      return this.$store.getters["account/isSuper"];
    },
    isAdmin() {
      return this.$store.getters["account/isAdmin"];
    },
    isLogist() {
      return this.$store.getters["account/isLogist"];
    },
  },

  async mounted() {
    this.loadOrderStates();
    this.loadTransportDepartment();
    this.loadLosists();
  },

  methods: {
    async loadLosists() {
      this.logists = [];
      const result = await this.$api.auth.getLogists();
      if (result.logists) {
        this.logists = result.logists;
      }
    },

    async loadOrderStates() {
      this.orderStates = [];
      const result = await this.$api.order.getOrderStatuses();
      if (result.list) {
        this.orderStates = result.list;
        if (this.orderStates.length > 0) {
          this.stateID = this.orderStates[0].id;
        }
      }
    },

    async loadTransportDepartment() {
      this.tdepartment = [];
      const result = await this.$api.company.getTransportDepartment();
      if (result.departments) {
        this.tdepartment = result.departments;
      }
    },

    async onSave() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      if (this.order.logistID === 0) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать логиста!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      if (this.order.customerID === 0) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать Закачика!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      this.order.state = this.stateID;
      const order = utf8_to_b64(JSON.stringify(this.order));
      const result = await this.$api.order.updateOrder({ payload: order });
      if (result.ok) {
        this.$router.push("/orders");
      }
      this.UIblock = false;
    },

    async searchCustomer() {
      const result = await this.$refs.searcheasyDlg.show({
        title: "Введите ИНН или название компании ",
        searchFunc: this.$api.contragetns.searchCustomer,
        okButton: "ОК",
      });
      if (result.ok) {
        this.customerCard = result.value;
        this.order.customerID = result.value.id;
        this.customerText = `ИНН ${this.customerCard.inn}, ${this.customerCard.formType} ${this.customerCard.name1}`;
      }
    },

    paymantUpdate(value) {
      this.order.payment.debet = value.value;
      this.order.payment.taxID = value.taxID;
    },
  },
};
</script>

<style></style>
