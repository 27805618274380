<template>
  <popup-modal ref="popup">
    <div style="width: 640px">
      <h2 style="margin-top: 0">{{ title }}</h2>

      <div style="margin-top: 20px">
        <select v-model="value">
          <option v-for="item in list" :key="item.id" :value="item.index">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="row button meduim" style="margin-top: 16px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";

export default {
  name: "SelectDlg",
  components: {
    PopupModal,
  },
  data: () => ({
    title: undefined,
    value: "",
    list: [],
    okButton: "Войти",
    cancelButton: "Отмена", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.value = opts.value;
      this.list = opts.list;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.value });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },
  },
};
</script>

<style></style>
