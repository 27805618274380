<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Запросы</h1></div>
      <div class="button m-left" v-if="isSuper||isRequester">
        <button class="white bg-prime-prime-blue" @click="$router.push('/request/0')">добавить</button>
      </div>
    </div>
    <div class="card new">
      <div class="row-center-justify">
        <div>
          <h2>Обработанные запросы </h2>
        </div>
        <search-box 
          :placeholder="'Введите слово для поиска по всем закрытым запросам'"
          :style="'search--light'"
          @search="searchInDone"
        />
      </div>
      <requests-list
        :list="requestDone"
        @onClick="selectRequest"
      />
      <div v-if="false">
        <div class="table-pages row">
          <div>Строки с 1 по 1 из 1</div>
          <div class="row m-left">
            <div class="table-pages__page link">	&lt;</div>
            <div class="table-pages__page link">1</div>
            <div class="table-pages__page this">1</div>
            <div class="table-pages__page">...</div>
            <div class="table-pages__page link">1</div>
            <div class="table-pages__page link">1</div>
            <div class="table-pages__page link">&gt;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { utf8_to_b64 } from '@/assets/js/base64.js';
import RequestsList from '@/components/orders/RequestsList.vue'
import SearchBox from '@/components/app/ui/SearchBox.vue'

export default {
  name: 'RequestsClosedView',

  components: {
    RequestsList, SearchBox,
  },

  computed: {
    isSuper () {
      return store.getters['account/isSuper']
    },
    isRequester() {
      return store.getters['account/isRequester']
    },
  },

  data: () => ({
    requestDone: [],
    updateTimeout : null,
  }),

  mounted () {
    this.loadRequest()
    this.updateTimeout = setInterval(this.updateState, 60000)
  },

  unmounted () {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout)
    }
  },

  methods : {
    updateState() {
      this.loadRequest()
    },

    async loadRequest() {
      let filter = utf8_to_b64(JSON.stringify({mode: 'done'}))
      let result = await this.$api.order.getRequests(filter)
      if (result.list) {
        this.requestDone = result.list
      }
    },

    selectRequest(requestID) {
      this.$router.push('/request/'+requestID)
    },

    async searchInDone(tags) {
      let filter = utf8_to_b64(JSON.stringify({mode: 'done', tags }))
      let result = await this.$api.order.getRequests(filter)
      if (result.list) {
        this.requestDone = result.list
      }
    },
  }
}
</script>

<style>

</style>