<template>
  <div>
    <div>
      <h1>Наши водители</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyDriversView",
};
</script>

<style></style>
