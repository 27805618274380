<template>
  <div class="loader__inner">
    <div class="loader">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderBox",
};
</script>

<style>
.loader__inner {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  color: #f1f4f9;
  font-size: 150px;
}
</style>
