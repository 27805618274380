<template>
  <div class="card new">
    <div>Раздел в разработке.</div>
  </div>
</template>

<script>
export default {
  name: "ContragentAccountCard",
};
</script>

<style></style>
