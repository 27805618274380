import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/DashboardView.vue";
import QuitView from "../views/QuitView.vue";
import AccountsView from "../views/users/AccountsView.vue";
import RequestsView from "../views/orders/RequestsView.vue";
import RequestsClosedView from "../views/orders/RequestsClosedView.vue";
import AccountPropsView from "../views/users/AccountPropsView.vue";
import RequestPropsView from "../views//orders//RequestPropsView.vue";
import RequestReportsView from "../views/orders/RequestReportsView.vue";
import LogistQuenueView from "../views/users/LogistQuenueView.vue";
import AdminEventView from "../views/admin/AdminEventView.vue";
import CompanyStructure from "../views/company/CompanyStructure.vue";
import FinanceOurCompany from "../views/company/FinanceOurCompany.vue";
import CompanyTrucksView from "../views/company/CompanyTrucksView.vue";
import CompanyDriversView from "../views/company/CompanyDriversView.vue";
import CompanyStorageView from "../views/company/CompanyStorageView.vue";
import CompCardView from "../views/company/CompCardView.vue";
import NotificationsView from "../views/notifications/NotificationsView.vue";
import NotificationPropView from "../views/notifications/NotificationPropView.vue";
import CompanyStaffView from "../views/company/CompanyStaffView.vue";
import OrderNewView from "../views/orders/OrderNewView.vue";
import OrderListView from "../views/orders/OrderListView.vue";
import CustomersView from "../views/orders/CustomersView.vue";
import ContractorsView from "../views/orders/ContractorsView.vue";
import OrderView from "../views/orders/OrderView.vue";
import AdditionalServicesView from "../views/services/AdditionalServicesView.vue";
import CargoView from "../views/cargo/CargoView.vue";
import TrucksTypesView from "../views/transport/TrucksTypesView.vue";
import TruckView from "../views/transport/TruckView.vue";
import DictionariesView from "../views/misc/DictionariesView.vue";
import UnitsView from "../views/misc/UnitsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "empty" },
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "main", auth: true },
    component: DashboardView,
  },
  {
    path: "/signout",
    name: "QuitView",
    meta: { layout: "empty" },
    component: QuitView,
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { layout: "main", auth: true },
    component: NotificationsView,
  },
  {
    path: "/notification/:id",
    name: "notification",
    meta: { layout: "main", auth: true },
    component: NotificationPropView,
  },

  {
    path: "/accounts",
    name: "accounts",
    meta: { layout: "main", auth: true },
    component: AccountsView,
  },
  {
    path: "/account/:id",
    name: "accountProp",
    meta: { layout: "main", auth: true },
    component: AccountPropsView,
  },
  {
    path: "/logistquenue",
    name: "logistquenue",
    meta: { layout: "main", auth: true },
    component: LogistQuenueView,
  },

  {
    path: "/requests",
    name: "requests",
    meta: { layout: "main", auth: true },
    component: RequestsView,
  },
  {
    path: "/requests/closed",
    name: "requests_closed",
    meta: { layout: "main", auth: true },
    component: RequestsClosedView,
  },
  {
    path: "/requests/reports",
    name: "requests_reports",
    meta: { layout: "main", auth: true },
    component: RequestReportsView,
  },

  {
    path: "/request/:id",
    name: "requestProp",
    meta: { layout: "main", auth: true },
    component: RequestPropsView,
  },

  {
    path: "/admin_event",
    name: "admin_event",
    meta: { layout: "main", auth: true },
    component: AdminEventView,
  },

  {
    path: "/company/structure",
    name: "company_structure",
    meta: { layout: "main", auth: true },
    component: CompanyStructure,
  },
  {
    path: "/compcard/:id",
    name: "CompCard",
    meta: { layout: "main", auth: true },
    component: CompCardView,
  },
  {
    path: "/company/staff",
    name: "company_staff",
    meta: { layout: "main", auth: true },
    component: CompanyStaffView,
  },

  {
    path: "/finance/company",
    name: "finance_company",
    meta: { layout: "main", auth: true },
    component: FinanceOurCompany,
  },

  {
    path: "/company/trucks",
    name: "company_trucks",
    meta: { layout: "main", auth: true },
    component: CompanyTrucksView,
  },
  {
    path: "/company/drivers",
    name: "company_drivers",
    meta: { layout: "main", auth: true },
    component: CompanyDriversView,
  },
  {
    path: "/company/storage",
    name: "company_storage",
    meta: { layout: "main", auth: true },
    component: CompanyStorageView,
  },

  {
    path: "/neworder",
    name: "order_new",
    meta: { layout: "main", auth: true },
    component: OrderNewView,
  },

  {
    path: "/orders",
    name: "orders",
    meta: { layout: "main", auth: true },
    component: OrderListView,
  },
  {
    path: "/order/:id",
    name: "order",
    meta: { layout: "main", auth: true },
    component: OrderView,
  },

  {
    path: "/customers",
    name: "customers",
    meta: { layout: "main", auth: true },
    component: CustomersView,
  },

  {
    path: "/contractors",
    name: "contractors",
    meta: { layout: "main", auth: true },
    component: ContractorsView,
  },

  {
    path: "/services/additional",
    name: "services_additional",
    meta: { layout: "main", auth: true },
    component: AdditionalServicesView,
  },
  {
    path: "/cargo",
    name: "cargo",
    meta: { layout: "main", auth: true },
    component: CargoView,
  },
  {
    path: "/trucks/types",
    name: "trucks_types",
    meta: { layout: "main", auth: true },
    component: TrucksTypesView,
  },
  {
    path: "/truck/:id",
    name: "truck",
    meta: { layout: "main", auth: true },
    component: TruckView,
  },
  {
    path: "/dictionaries",
    name: "dictionaries",
    meta: { layout: "main", auth: true },
    component: DictionariesView,
  },
  {
    path: "/units",
    name: "units",
    meta: { layout: "main", auth: true },
    component: UnitsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    if (!store.getters["account/isAuthenticated"]) {
      next({ name: "home" });
      return;
    }
  }
  next();
});

export default router;
