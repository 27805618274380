<template>
  <loader-box v-if="loading" />
  <div :class="{ 'quarter-3': currentTabID != 5 }" v-else>
    <div>
      <h1>Заявка {{ order.number }}</h1>
    </div>
    <tabs-box
      v-if="tabs.length > 1"
      :tabs="tabs"
      :defaultTabID="0"
      @selected="onTabSelected"
    />
    <div v-if="currentTabID == 0">
      <order-general />
    </div>
    <div v-if="currentTabID == 1">
      <order-route />
    </div>
    <div v-if="currentTabID == 2">
      <order-cargo />
    </div>
    <div v-if="currentTabID == 3">
      <order-cars />
    </div>
    <div v-if="currentTabID == 4">
      <order-contractors />
    </div>
    <div v-if="currentTabID == 5">
      <order-finance />
    </div>

    <div class="row new" v-if="currentTabID == 0">
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="onSave">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { utf8_to_b64 } from "@/assets/js/base64.js";
import TabsBox from "@/components/app/ui/TabsBox.vue";
import OrderGeneral from "@/components/orders/OrderGeneral.vue";
import OrderRoute from "@/components/orders/OrderRoute.vue";
import OrderCargo from "@/components/orders/OrderCargo.vue";
import OrderCars from "@/components/orders/OrderCars.vue";
import OrderContractors from "@/components/orders/OrderContractors.vue";
import OrderFinance from "@/components/orders/OrderFinance.vue";
import LoaderBox from "@/components/app/ui/LoaderBox.vue";

export default {
  name: "OrderView",

  components: {
    TabsBox,
    OrderGeneral,
    LoaderBox,
    OrderRoute,
    OrderCargo,
    OrderCars,
    OrderContractors,
    OrderFinance,
  },
  data: () => ({
    loading: true,
    tabsArray: [
      { id: 0, title: "Общие данные" },
      { id: 1, title: "Маршрут" },
      { id: 2, title: "Груз" },
      { id: 4, title: "Исполнители" },
      { id: 3, title: "Транспорт" },
      { id: 5, title: "Финансы" },
    ],

    currentTabID: 0,
  }),

  computed: {
    order() {
      return this.$store.getters["order/getOrder"];
    },
    tabs() {
      /*
      let result = [];
      if (this.card.id == 0) {
        result.push(this.tabsArray[0]);
      } else {

        result = this.tabsArray.filter(
          (element) =>
            element.comptypeID === -1 ||
            element.comptypeID === this.card.comptypeID ||
            (element.comptypeID === -2 &&
              this.card.companyfilter == "company_staff")
              );
            }

            return result;
            */
      return this.tabsArray;
    },
  },

  mounted() {
    if (this.$route.params && this.$route.params["id"] != "0") {
      this.reload(this.$route.params["id"]);
    }
    this.onTabSelected(this.tabsArray[0]);
  },

  unmounted() {
    this.$store.dispatch("order/clear");
  },

  methods: {
    reload(orderID) {
      this.order.id = orderID;
      this.load();
    },

    async load() {
      const result = await this.$api.order.getOrder(this.order.id);
      if (result.order) {
        this.$store.dispatch("order/set", result.order);
      }
      this.loading = false;
    },

    onTabSelected(tab) {
      this.currentTabID = tab.id;
    },

    async onSave() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      const order = utf8_to_b64(JSON.stringify(this.order));
      const result = await this.$api.order.updateOrder({ payload: order });
      if (result.ok) {
        this.$router.push("/orders");
      }
      this.UIblock = false;
    },
  },
};
</script>

<style></style>
