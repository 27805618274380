<template>
  <div style="margin-right: 50px">
    <div class="row-center">
      <div><h1>Наш транспорт</h1></div>
      <div class="button m-left">
        <button class="white bg-prime-prime-blue" @click="onAdd()">
          Добавить
        </button>
      </div>
    </div>

    <div class="card new">
      <table>
        <thead>
          <th width="50px">#</th>
          <th>Наименование</th>
          <th>Марка</th>
          <th>Модель</th>
          <th>Госномер</th>
          <th>Пробег</th>
          <th>Дата фиксвции пробега</th>
          <th>Дата последнего ТО</th>
          <th>Дата следующего ТО</th>
        </thead>
        <tbody>
          <tr v-for="item in trucks" :key="item.id" @click="onEdit(item)">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.maker }}</td>
            <td>{{ item.model }}</td>
            <td>{{ item.number }}</td>
            <td colspan="4"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <input-dlg ref="inputDlg" />
  </div>
</template>

<script>
import InputDlg from "@/components/modals/app/InputDlg.vue";

export default {
  name: "CompanyTrucksView",

  components: {
    InputDlg,
  },

  data: () => ({
    trucks: [],
  }),

  mounted() {
    this.load();
  },

  methods: {
    async onAdd() {
      const response = await this.$refs.inputDlg.show({
        title: "Условное название нового транспортного средства",
        value: "",
        okButton: "Создать",
      });
      if (response.ok) {
        const result = await this.$api.cars.createOurTruck({
          title: response.value,
        });
        if (result.id) {
          this.load();
        }
      }
    },

    onEdit(item) {
      this.$router.push("/truck/" + item.id);
    },

    async load() {
      const result = await this.$api.cars.getOurTrucks();
      if (result.list) {
        this.trucks = result.list;
      }
    },
  },
};
</script>

<style></style>
