<template>
  <div>
    <div class="row-center">
      <div>
        <h2>{{ title }} </h2>
      </div>
      <search-box 
        class=" m-left m-right-8"
        :placeholder="'Введите слово для поиска'"
        :style="'search--light'"
        @search="search"
      />
      <div class="button small" v-if="canAdd">
        <button class="white bg-prime-prime-blue" @click="onAdd">Добавить</button>
      </div>
    </div>
    <div>
        <table-box
          :header="tableHeader"
          :body="tableBody"
          @edit="onEdit"
        />
    </div>
    <input-dlg ref="inputDlg" />
  </div>
</template>

<script>
import SearchBox from '@/components/app/ui/SearchBox.vue'
import TableBox from '@/components/app/ui/TableBox.vue'
import InputDlg from '@/components/modals/app/InputDlg.vue'
import { getStatusIcon } from '@/assets/js/icons.js'
import { /*b64_to_utf8,*/ utf8_to_b64 } from '@/assets/js/base64.js';

export default {
  name: 'EasyDict',
  components : {
    SearchBox, TableBox, InputDlg
  },

  props: {
    'title' : String,
    'canAdd' : Boolean,
    'canEdit' : Boolean,
    'api' : String,
    'getList' : Function,
    'add' : Function,
    'update' : Function,
  },

  data: () => ({
    tableHeader: [
      {id: 2, title : 'ID', width: '10%', align:'center'},
      {id: 3, title : '', width: '80%', align:'left'},
      {id: 8, title : 'Статус', width: '10%', align:'center'},
    ],

    tableBody: []
  }),

  async mounted () {
    if (this.title) {
      this.tableHeader[1].title = this.title
    }
    this.loadBody()
  },

  methods : {
    search () {

    },

    async loadBody() {
      this.tableBody = []
      if (this.getList) {
        const result = await this.getList('all')
        if (result.list) {
          result.list.forEach((element) => {
            this.tableBody.push({
                id: element.id,
                status: element.status,
                row: [
                {id: 1, value: element.id},
                {id: 2, value: element.title},
                {id: 3, value: getStatusIcon(element.status)}
                ]
            })
          })
        }
      }
    },

    async onAdd() {
      const result = await this.$refs.inputDlg.show({
          title: 'Введите название нового '+ this.title,
          value: '',
          okButton: 'ОК',
      })

      if ((result.ok)&&(result.value !== '')&&(this.add)) {
        result.value = utf8_to_b64(result.value)
        const result2 = await this.add({title : result.value})
        if (result2.ok) {
          this.loadBody()
        }
      }
    },
    async onEdit(event) {
      if (this.canEdit) {
        if (event.col < this.tableBody[event.row].row.length-1) {
          // это не статус
          const result = await this.$refs.inputDlg.show({
            title: 'Введите новое название',
            value: this.tableBody[event.row].row[event.col].value,
            okButton: 'ОК',
          })

          if ((result.ok)&&(result.value !== '')&&(this.update)) {
            let data = {
              id: this.tableBody[event.row].id,
              status: this.tableBody[event.row].status,
              title: utf8_to_b64(result.value)
            }

            const result2 = await this.update(data)
            if (result2.ok) {
              this.loadBody()
            }
          }
          return
        }

        // это статус
        let data = {
          id: this.tableBody[event.row].id,
          status: (this.tableBody[event.row].status) ? 0 : 1,
          title: utf8_to_b64(this.tableBody[event.row].row[1].value)
        }
        const result2 = await this.update(data)
            if (result2.ok) {
              this.loadBody()
            }
      }
    }
  }
}
</script>

<style>

</style>