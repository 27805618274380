<template>
  <div class="test-message" v-if="showTestMessage">Тестовая конфигурация!</div>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
  <toast-message />
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import ToastMessage from "@/components/app/ToastMessage.vue";

export default {
  name: "App",
  components: {
    EmptyLayout,
    MainLayout,
    ToastMessage,
  },

  data: () => ({
    showTestMessage: false,
  }),

  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

  async created() {
    const jwt = this.$route.query.jwt
      ? this.$route.query.jwt
      : this.$api.cookie.getCookie("jwt");
    if (jwt != "") {
      // Авторизованный пользователь - надо его проверить
      this.$api.auth.setJWT(jwt);
      const response = await this.$api.auth.validateJWT(jwt);
      if (response.id) {
        response.jwt = jwt;
        this.$store.dispatch("account/setAccount", response);
        this.$api.eventBus.emit("JWTchecked");
        if (this.$route.query.path) {
          this.$router.push(this.$route.query.path);
          return;
        }
        this.$router.push("/dashboard");
        return;
      }
      this.$api.auth.setJWT("");
      this.$api.cookie.setCookie("jwt", "", 1);
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    }
  },

  async mounted() {
    this.showTestMessage =
      window.location.host == "puchkov73.ru" ? true : false;
    this.$api.eventBus.on("network-error", this.network_error);

    // const jwt = this.$api.cookie.getCookie('jwt')
    // if (jwt != '') {
    //   // Авторизованный пользователь - надо его проверить
    //   this.$api.auth.setJWT(jwt)
    //   const response = await this.$api.auth.validateJWT(jwt)
    //   if ((response.id)) {
    //     response.jwt = jwt
    //     this.$store.dispatch('account/setAccount', response)
    //     if (this.$route.path == '/') {
    //       this.$router.push('/dashbord')
    //     }
    //     return
    //   }

    //   this.$api.auth.setJWT('')
    //   this.$api.cookie.setCookie('jwt', '', 1)
    //   if (this.$route.path != '/') {
    //       this.$router.push('/')
    //     }
    // }
  },

  methods: {
    network_error(error) {
      if (error.response && error.response.data && error.response.data.error) {
        this.$api.eventBus.emit("toast", {
          text: error.response.data.error,
          style: "error",
        });
        return;
      }
      this.$api.eventBus.emit("toast", {
        text: "Ошибка сети!",
        style: "error",
      });
    },
  },
};
</script>

<style scoped>
.test-message {
  background-color: red;
  color: white;
  font-size: 1.5em;
  padding: 2px 0;
  text-align: center;
}
</style>
