<template>
  <div :class="{ 'quarter-3': currentTabID != 5 }">
    <div>
      <h1>Склад</h1>
    </div>
    <tabs-box
      v-if="tabs.length > 1"
      :tabs="tabs"
      :defaultTabID="0"
      @selected="onTabSelected"
    />
    <div v-if="currentTabID == 3">
      <storage-nomenclature />
    </div>
  </div>
</template>

<script>
import TabsBox from "@/components/app/ui/TabsBox.vue";
import StorageNomenclature from "@/components/storage/StorageNomenclature.vue";
export default {
  name: "CompanyStorageView",

  components: {
    TabsBox,
    StorageNomenclature,
  },

  data: () => ({
    tabsArray: [
      { id: 0, title: "Остатки" },
      { id: 1, title: "Приход" },
      { id: 2, title: "Расход" },
      { id: 3, title: "Номенклатура" },
    ],

    currentTabID: 0,
  }),

  computed: {
    tabs() {
      /*
      let result = [];
      if (this.card.id == 0) {
        result.push(this.tabsArray[0]);
      } else {

        result = this.tabsArray.filter(
          (element) =>
            element.comptypeID === -1 ||
            element.comptypeID === this.card.comptypeID ||
            (element.comptypeID === -2 &&
              this.card.companyfilter == "company_staff")
              );
            }

            return result;
            */
      return this.tabsArray;
    },
  },

  mounted() {
    this.onTabSelected(this.tabsArray[0]);
  },
  methods: {
    // async load(truckID) {
    //   const result = await this.$api.cars.getTruck(truckID);
    //   if (result.truck) {
    //     this.$store.dispatch("truck/set", result.truck);
    //   }
    //   this.loading = false;
    // },

    onTabSelected(tab) {
      this.currentTabID = tab.id;
    },
  },
};
</script>

<style></style>
