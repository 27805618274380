<template>
  <popup-modal ref="popup">
    <div>
      <h2 style="margin-top: 0">Выберите дату</h2>

      <div style="margin-top: 20px">
        <VueDatePicker
          v-model="value.date"
          model-type="yyyy-MM-dd"
          format="dd.MM.yyyy"
          auto-apply
        ></VueDatePicker>
      </div>

      <div class="row button meduim" style="margin-top: 16px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "SelectDateDlg",
  components: {
    PopupModal,
    VueDatePicker,
  },
  data: () => ({
    value: {
      date: "",
    },
    okButton: "",
    cancelButton: "Отмена", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.value = opts.value;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      console.log(this.value.date);
      if (!this.value.date || this.value.date == "") {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать дату!",
          style: "error",
        });
        return;
      }
      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.value });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },
  },
};
</script>

<style></style>
