<template>
  <h2 style="margin-top: 0; margin-bottom: 10px" v-if="card.id == 0">
    Новое юридическое лицо (<span v-if="card.nationID == 0">российское</span
    ><span v-if="card.nationID == 1">иностранное</span>)
  </h2>

  <div class="label">Правовая форма</div>
  <div class="eighth">
    <select
      v-model.trim="localcard.formID"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    >
      <option v-for="form in forms" :key="form.id" :value="form.id">
        {{ form.title }}
      </option>
    </select>
  </div>

  <div class="label">Полное наименование</div>
  <div>
    <input
      type="text"
      v-model.trim="localcard.name1"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>
  <div class="label">Сокращенное наименование</div>
  <div class="half">
    <input
      type="text"
      v-model.trim="localcard.name2"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>

  <div class="label">ИНН</div>
  <div class="row-center">
    <div class="eighth m-right-8">
      <input-control
        :initvalue="localcard.inn"
        @accept="searchINN"
        :disabled="!canEdit"
      />
    </div>
    <i class="fa fa-spinner fa-spin" v-if="innState == 'loading'"></i>
    <i
      class="fa fa-check"
      style="color: limegreen"
      v-if="innState == 'new'"
    ></i>
  </div>
  <div class="label">ОРГН</div>
  <div class="eighth">
    <input
      type="text"
      v-model.trim="localcard.ogrn"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>
</template>

<script>
import InputControl from "@/components/app/ui/InputControl.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "CompanyData",
  components: {
    InputControl,
  },
  props: {
    card: {
      type: Object,
      default: () => ({
        id: 1,
        nationID: 0,
        name1: "1",
        name2: "",
        name3: "",
      }),
    },
    wantSave: Boolean,
  },

  emits: ["save", "cantSave", "canSave", "reload"],

  watch: {
    wantSave(newvalue) {
      if (newvalue) {
        // значит кто-то наверху хочет сохранить данные и требует проверить их корректность.
        this.validate();
      }
    },

    card: {
      handler(newCard) {
        this.localcard = newCard;
        console.log("1", this.localcard);
      },
      deep: true,
    },
  },

  data: () => ({
    localcard: {
      id: 0,
      companyfilter: "",
      formID: 3,
      nationID: 0,
      name1: "",
      name2: "",
      name3: "",
      birthday: "",
      inn: "",
      snils: "",
      ogrn: "",
      status: 1,
    },

    forms: [
      { id: 3, title: "ООО" },
      { id: 4, title: "ЗАО" },
      { id: 5, title: "ОАО" },
    ],

    innState: "",
    reinit: false,
  }),

  computed: {
    canEdit() {
      return true;
    },
  },

  async mounted() {
    this.$api.eventBus.on("refresh", () => {
      this.localcard = this.card;
      this.reinit = !this.reinit;
    });

    this.localcard = this.card;
    this.reinit = !this.reinit;

    if (this.$route.query) {
      if (this.$route.query["companyfilter"]) {
        this.localcard.companyfilter = this.$route.query["companyfilter"];
      }
    }
  },

  methods: {
    validate() {
      if (this.localcard.inn == "") {
        this.$api.eventBus.emit("toast", {
          text: "ИНН не может быть пустым",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }
      if (this.localcard.name1 == "") {
        this.$api.eventBus.emit("toast", {
          text: "Полное наименование не может быть пустой",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }

      if (this.localcard.name2 == "") {
        this.$api.eventBus.emit("toast", {
          text: "Сокращенное наименование не может быть пустым",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }

      this.$emit("canSave");
    },

    async searchINN(data) {
      this.innState = "";
      if (data.value !== "") {
        this.innState = "loading";
        this.localcard.inn = data.value;

        const result = await this.$api.contragetns.searchINN(
          utf8_to_b64(JSON.stringify(this.localcard))
        );
        if (result.card != null && result.card.id) {
          this.innState = "";

          this.$router.push(
            `/compcard/${result.card.id}?companyfilter=${this.localcard.companyfilter}`
          );

          this.$emit("reload", result.card.id);
          return;
        }
        this.innState = "new";
        this.$emit("save", this.localcard);
      }
    },
  },
};
</script>

<style></style>
