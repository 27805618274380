<template>
  <div class="card new">
    <div class="row-center">
      <div>
        <h2>Список номенклатры</h2>
      </div>
      <div class="button small" v-if="canAdd" style="margin-left: auto">
        <button class="white bg-prime-prime-blue" @click="onAdd">
          Добавить
        </button>
      </div>
    </div>

    <table style="margin: 16px 0">
      <thead>
        <th width="50px">#</th>
        <th>Наименование</th>
        <th width="150px">Единицы измерения</th>
        <th width="80px"></th>
      </thead>
      <tbody>
        <tr v-for="item in nomenclature" :key="item.id" @click="onEdit(item)">
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
          <td>{{ getNomenclatureTitle(item.unitID) }}</td>
        </tr>
      </tbody>
    </table>
    <storage-nomenclature-dlg ref="storageNomenclatureDlg" />
  </div>
</template>

<script>
import StorageNomenclatureDlg from "@/components/modals/storage/StorageNomenclatureDlg.vue";
export default {
  name: "StorageNomenclature",

  components: {
    StorageNomenclatureDlg,
  },

  computed: {
    canAdd() {
      return true;
    },
  },

  async mounted() {
    const result = await this.loadUnits();
    if (result) {
      this.load();
    }
  },

  data: () => ({
    nomenclature: [],
    units: [],
  }),

  methods: {
    async loadUnits() {
      this.units = [];
      const result = await this.$api.units.getList();
      if (result.list) {
        this.units = result.list;
      }
      return true;
    },
    async load() {
      const result = await this.$api.storage.getNomenclature();
      if (result.list) {
        this.nomenclature = result.list;
      }
    },

    async onAdd() {
      const result = await this.$refs.storageNomenclatureDlg.show({
        title: "Добавить номенклатуру",
        value: {
          title: "",
          unitID: 0,
        },
        okButton: "Создать",
        list: this.units,
      });
      if (result.ok) {
        const result2 = await this.$api.storage.createNomenclature({
          payload: result.value,
        });
        if (result2.ok) {
          this.load();
        }
      }
    },

    getNomenclatureTitle(id) {
      return this.units.find((element) => element.id == id).title;
    },

    async onEdit(item) {
      const result = await this.$refs.storageNomenclatureDlg.show({
        title: "Редактировать номенклатуру",
        value: { ...item },
        okButton: "Сохранить",
        list: this.units,
      });
      if (result.ok) {
        const result2 = await this.$api.storage.updateNomenclature(item.id, {
          payload: result.value,
        });
        if (result2.ok) {
          this.load();
        }
      }
    },
  },
};
</script>

<style></style>
