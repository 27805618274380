<template>
  <div>
    <div class="card new">
      <div class="row-center">
        <div>
          <h2>Транспорт и его использование</h2>
        </div>
        <div class="button small" v-if="canAdd" style="margin-left: auto">
          <button class="white bg-prime-prime-blue" @click="onAdd">
            Добавить
          </button>
        </div>
      </div>
    </div>
    <div class="trucks-empty">Транспорт не задан</div>
  </div>
</template>

<script>
export default {
  name: "OrderCars",

  computed: {
    canAdd() {
      return true;
    },

    data: () => ({
      trucks: [],
    }),
  },

  methods: {
    onAdd() {
      alert("dddd");
    },
  },
};
</script>

<style>
.trucks-empty {
  margin: 20px 20px 100px 20px;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}
</style>
