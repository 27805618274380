<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Реквизиты</h2>

    <div class="label" style="margin-top: 20px">Наименование реквизитов</div>
    <div><input type="text" v-model="card.title" /></div>
    <div class="label" style="margin-top: 8px">БИК банка (или SWIFT)</div>
    <div class="half">
      <input type="text" v-model="card.bank.bik" @keydown="onKey" />
    </div>

    <div class="card new">
      <div class="label">Наеименование банка</div>
      <div><input type="text" v-model="card.bank.title" readonly /></div>
      <div class="row" style="margin-top: 8px">
        <div class="half m-right-16">
          <div class="label">Коррсчет банка</div>
          <div><input type="text" v-model="card.bank.korr" readonly /></div>
        </div>
        <div class="quarter">
          <div class="label">ИНН банка</div>
          <div><input type="text" v-model="card.bank.inn" readonly /></div>
        </div>
        <div class="quarter">
          <div class="label">SWIFT банка</div>
          <div><input type="text" v-model="card.bank.swift" readonly /></div>
        </div>
      </div>
      <div class="label" style="margin-top: 8px">Адресс банка</div>
      <div><input type="text" v-model="card.bank.address" readonly /></div>
    </div>

    <div style="margin-top: 10px">
      <div class="label">Счет в банке</div>
      <div class="half"><input type="text" v-model="card.accountnumber" /></div>
      <div class="label" style="margin-top: 8px">
        Наименование получателя платежа
      </div>
      <div><input type="text" v-model="card.accountname" /></div>
    </div>

    <div class="row button meduim" style="margin-top: 24px">
      <div class="m-left m-right-8">
        <button
          class="white bg-prime-prime-blue medium"
          @click.prevent="_confirm"
        >
          {{ okButton }}
        </button>
      </div>
      <div>
        <button class="white bg-prime-prime-blue medium" @click="_cancel">
          {{ cancelButton }}
        </button>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";

export default {
  name: "BankDetailsDlg",

  components: {
    PopupModal,
  },
  data: () => ({
    card: {
      id: 0,
      title: "",
      bank: {
        bik: "",
        title: "",
        korr: "",
        inn: "",
        swift: "",
        address: "",
      },
      accountnumber: "",
      accountname: "",
    },
    searchTimer: null,

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    onKey() {
      this.clearCard();
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(this.search, 400);
    },

    async search() {
      if (this.card.bank.bik.length >= 9) {
        const result = await this.$api.external.getBankByBIK(
          this.card.bank.bik
        );
        if (!result.suggestions || result.suggestions.length == 0) {
          this.$api.eventBus.emit("toast", {
            text: `Банк с таким БИК ${this.card.bank.bik} не найден!`,
            style: "error",
          });
          return;
        }
        this.card.bank = {
          bik: this.card.bank.bik,
          title: result.suggestions[0].data.name.payment,
          korr: result.suggestions[0].data.correspondent_account,
          inn: result.suggestions[0].data.inn,
          swift: result.suggestions[0].data.swift,
          address: result.suggestions[0].data.address.unrestricted_value,
        };
        //044525225
      }
    },

    show(opts = {}) {
      this.card = opts.value;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      this.search();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    clearCard() {
      this.card.bank = {
        bik: this.card.bank.bik,
        title: "",
        korr: "",
        inn: "",
        address: "",
      };
    },

    async _confirm() {
      if (this.card.title == "") {
        this.$api.eventBus.emit("toast", {
          text: "Название реквизитов не может быть пустым!",
          style: "error",
        });
        return;
      }

      if (this.card.bank.korr == "") {
        this.$api.eventBus.emit("toast", {
          text: "Небходимо задать корреспондентский счет!",
          style: "error",
        });
        return;
      }

      if (this.card.acountNumber == "") {
        this.$api.eventBus.emit("toast", {
          text: "Номер счета не может быть пустым!",
          style: "error",
        });
        return;
      }

      if (this.card.accountName == "") {
        this.$api.eventBus.emit("toast", {
          text: "Наименование получателя не может быть пустым!",
          style: "error",
        });
        return;
      }

      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.card });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },
  },
};
</script>

<style></style>

/* { "opf": { "type": "BANK", "full": null, "short": null }, "name": {
"payment": "ПАО Сбербанк", "full": null, "short": "ПАО Сбербанк" }, "bic":
"044525225", "swift": "SABRRUMM012", "inn": "7707083893", "kpp": "773601001",
"okpo": null, "correspondent_account": "30101810400000000225",
"treasury_accounts": null, "registration_number": "1481", "payment_city": "г
Москва", "state": { "status": "ACTIVE", "code": null, "actuality_date":
1701561600000, "registration_date": 677376000000, "liquidation_date": null },
"rkc": null, "cbr": { "opf": { "type": "CBR", "full": null, "short": null },
"name": { "payment": "ГУ БАНКА РОССИИ ПО ЦФО", "full": null, "short": null },
"bic": "044525000", "swift": null, "inn": null, "kpp": null, "okpo": null,
"correspondent_account": null, "treasury_accounts": null, "registration_number":
null, "payment_city": "г Москва 35", "state": { "status": "ACTIVE", "code":
null, "actuality_date": 1701561600000, "registration_date": null,
"liquidation_date": null }, "rkc": null, "cbr": null, "address": { "value":
"115035, г Москва 35, ул Балчуг, 2", "unrestricted_value": "115035, г Москва 35,
ул Балчуг, 2", "data": null }, "phones": null }, "address": { "value": "г
Москва, ул Вавилова, д 19", "unrestricted_value": "117312, г Москва,
Академический р-н, ул Вавилова, д 19", "data": { "postal_code": "117312",
"country": "Россия", "country_iso_code": "RU", "federal_district":
"Центральный", "region_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
"region_kladr_id": "7700000000000", "region_iso_code": "RU-MOW",
"region_with_type": "г Москва", "region_type": "г", "region_type_full": "город",
"region": "Москва", "area_fias_id": null, "area_kladr_id": null,
"area_with_type": null, "area_type": null, "area_type_full": null, "area": null,
"city_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5", "city_kladr_id":
"7700000000000", "city_with_type": "г Москва", "city_type": "г",
"city_type_full": "город", "city": "Москва", "city_area": "Юго-западный",
"city_district_fias_id": null, "city_district_kladr_id": null,
"city_district_with_type": "Академический р-н", "city_district_type": "р-н",
"city_district_type_full": "район", "city_district": "Академический",
"settlement_fias_id": null, "settlement_kladr_id": null, "settlement_with_type":
null, "settlement_type": null, "settlement_type_full": null, "settlement": null,
"street_fias_id": "25f8f29b-b110-40ab-a48e-9c72f5fb4331", "street_kladr_id":
"77000000000092400", "street_with_type": "ул Вавилова", "street_type": "ул",
"street_type_full": "улица", "street": "Вавилова", "stead_fias_id": null,
"stead_cadnum": null, "stead_type": null, "stead_type_full": null, "stead":
null, "house_fias_id": "93409d8c-d8d4-4491-838f-f9aa1678b5e6", "house_kladr_id":
"7700000000009240170", "house_cadnum": "77:06:0002008:1036", "house_type": "д",
"house_type_full": "дом", "house": "19", "block_type": null, "block_type_full":
null, "block": null, "entrance": null, "floor": null, "flat_fias_id": null,
"flat_cadnum": null, "flat_type": null, "flat_type_full": null, "flat": null,
"flat_area": null, "square_meter_price": null, "flat_price": null,
"room_fias_id": null, "room_cadnum": null, "room_type": null, "room_type_full":
null, "room": null, "postal_box": null, "fias_id":
"93409d8c-d8d4-4491-838f-f9aa1678b5e6", "fias_code": "77000000000000009240170",
"fias_level": "8", "fias_actuality_state": "0", "kladr_id":
"7700000000009240170", "geoname_id": "524901", "capital_marker": "0", "okato":
"45293554000", "oktmo": "45397000", "tax_office": "7736", "tax_office_legal":
"7736", "timezone": "UTC+3", "geo_lat": "55.7001865", "geo_lon": "37.5802234",
"beltway_hit": "IN_MKAD", "beltway_distance": null, "metro": [ { "name":
"Ленинский проспект", "line": "Калужско-Рижская", "distance": 0.8 }, { "name":
"Площадь Гагарина", "line": "МЦК", "distance": 0.8 }, { "name": "Академическая",
"line": "Калужско-Рижская", "distance": 1.5 } ], "divisions": null, "qc_geo":
"0", "qc_complete": "5", "qc_house": "2", "history_values": null,
"unparsed_parts": null, "source": "117312, г Москва, улица Вавилова, дом 19",
"qc": "0" } }, "phones": null }*/
