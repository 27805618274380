<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Категории грузов</h1></div>
      <div class="button m-left">
        <button class="white bg-prime-prime-blue" @click="onAdd()">
          Добавить
        </button>
      </div>
    </div>
    <div class="card new">
      <table>
        <thead>
          <th width="50px">#</th>
          <th>Наименование</th>
          <th width="100px">Статус</th>
        </thead>
        <tbody>
          <tr
            v-for="item in services"
            :key="item.id"
            class="cursor"
            @click="onEdit(item)"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td></td>
          </tr>
          <tr v-if="services.length == 0">
            <td colspan="10"><i>Список категорий грузов пуст</i></td>
          </tr>
        </tbody>
      </table>
    </div>
    <input-dlg ref="inputDlg" />
  </div>
</template>

<script>
import InputDlg from "@/components/modals/app/InputDlg";
import { utf8_to_b64 } from "@/assets/js/base64.js";
export default {
  name: "CargoView",

  components: {
    InputDlg,
  },

  data: () => ({
    services: [],
  }),

  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.services = [];
      const result = await this.$api.cargo.getCategories();
      if (result.list) {
        this.services = result.list;
      }
    },
    async onAdd() {
      const result = await this.$refs.inputDlg.show({
        title: "Веведите название новой категории груза",
        value: "", //
        okButton: "Создать",
      });
      if (result.ok) {
        const result2 = await this.$api.cargo.createCategories({
          payload: utf8_to_b64(
            JSON.stringify({ title: result.value, folderID: 0 })
          ),
        });
        if (result2.id) {
          this.load();
        }
      }
    },

    async onEdit(item) {
      const result = await this.$refs.inputDlg.show({
        title: "Веведите новое название категории груза",
        value: item.title, //
        okButton: "Создать",
      });
      if (result.ok) {
        const result2 = await this.$api.cargo.updateCategories(item.id, {
          payload: utf8_to_b64(
            JSON.stringify({ id: item.id, title: result.value, folderID: 0 })
          ),
        });
        if (result2.ok) {
          this.load();
        }
      }
    },
  },
};
</script>

<style></style>
