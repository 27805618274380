<template>
  <div class="autocomlete">
    <input
      type="text"
      v-model="value"
      @keydown="onKey"
      :disabled="disabled"
      @click="showResult = false"
    />
    <div class="autocomlete__result" :class="{ none: !showResult }">
      <ul>
        <li v-for="item in autocomplete" :key="item.id" @click="selected(item)">
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputAutocomplete",

  props: {
    initvalue: String,
    initID: Number,
    disabled: Boolean,
    searchFunc: Function,
    selectedFunc: Function,
    reinit: Boolean,
  },

  emits: ["accept"],

  watch: {
    initvalue: {
      handler(newValue) {
        this.value = newValue;
      },
      deep: true,
    },
    reinit: {
      handler() {
        this.value = this.initvalue;
        this.valueID = this.initID;
      },
      deep: true,
    },
  },

  data: () => ({
    showResult: false, // признак того, что надо показывать результат автопоиска
    valueID: 0,
    value: "",
    autocomplete: [],
    waitTimer: null,
  }),

  mounted() {
    this.value = this.initvalue;
    this.valueID = this.initID;
  },

  methods: {
    onKey() {
      this.showResult = false;
      this.valueID = this.value == this.initvalue ? this.initID : 0;
      if (this.waitTimer) {
        clearTimeout(this.waitTimer);
      }
      this.waitTimer = setTimeout(this.accept, 600);
    },

    async accept() {
      if (this.searchFunc) {
        this.autocomplete = await this.searchFunc(this.value);
        if (this.autocomplete.length > 0) {
          this.showResult = true;
        }
      }
    },

    selected(item) {
      this.showResult = false;
      this.value = item.title;
      this.valueID = item.id;
      if (this.selectedFunc) {
        this.selectedFunc(item);
      }
    },
  },
};
</script>

<style></style>
