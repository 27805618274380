<template>
  <div>
    <div class="row-center">
      <div><h1>Права и события</h1></div>
      <div class="button m-left">
        <button class="white bg-prime-prime-blue" @click="addAction">добавить действие</button>
      </div>
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="addFolder">добавить раздел</button>
      </div>
    </div>

    <div class="card new">
      <table>
        <thead>
          <th width="30px">#</th>
          <th>Действие</th>
          <th v-for="(role, index) in eventModel.roles" :key="role.id"
            class="w-center cursor"
            @click="onColumnClick(index)"
          >
            {{ role.title }}
          </th>
        </thead>
        <tbody
          v-for="actionGroup in eventModel.actions" :key="actionGroup.id"
        >
          <tr>
            <td :colspan="eventModel.roles.length+2" class="event-title">{{ actionGroup.title }}</td>
          </tr>
          <tr
            v-for="action in actionGroup.actions" :key="action.id"
          >
            <td>{{ action.id }}</td>
            <td>{{ action.title }}</td>
            <td v-for="(role, index) in action.roles" :key="role.id"
              @click="onClick(action, role)"
              :class="{'bg-prime-prime-green' : index == selectedColumn}"
            >
              <i v-if="role.check==1" class="fa fa-check i"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <input-dlg ref="inputDlg" />
    <select-dlg ref="selectDlg" />
  </div>
</template>

<script>
import InputDlg from '@/components/modals/app/InputDlg.vue'
import SelectDlg from '@/components/modals/app/SelectDlg.vue'
import { utf8_to_b64 } from '@/assets/js/base64.js';

export default {
  name: 'AdminEventView',
  components : {
    InputDlg, SelectDlg
  },
  data: () => ({
    eventModel : {
      roles: [],
      actions: []
    },
    selectedColumn : -1
  }),

  mounted () {
    this.LoadEventMpdel()
  },

  methods : {
    async LoadEventMpdel() {
      const result = await this.$api.auth.getActions()
      if (result.roles) {
        this.eventModel = result
      }

    },

    async onClick(action, role) {
      const result = await this.$api.auth.invertActionForRole({actionID: action.id, roleID: role.id})
      if (result.check) {
        role.check = result.check
      }
    },

    async addFolder () {
      const result = await this.$refs.inputDlg.show({
                title: 'Введите название нового раздела действий',
                value: '',
                okButton: 'ОК',
            })
      if (result.ok) {
        if (result.value != '') {
          const result2 = await this.$api.auth.addActionFolder({folderTitle :  utf8_to_b64(result.value) })
           
          if (result2.ok) {
            this.LoadEventMpdel()
          }
        }
      }

    },

    async addAction () {
      let folder = []
      this.eventModel.actions.forEach((element, index) => {
        folder.push({ id: element.id, title: element.title, index })
      })

      const result = await this.$refs.selectDlg.show({
                title: 'Выберите раздел действий',
                value: -1,
                list: folder,
                okButton: 'Выбрать',
            })

      if ((result.ok)&&(result.value > -1)) {
        const result2 = await this.$refs.inputDlg.show({
                title: 'Введите название нового действия в '+ folder[result.value].title,
                value: '',
                okButton: 'ОК',
            })
        if (result2.ok) {
          if (result2.value != '') {
            const result3 = await this.$api.auth.addAction(({folderID: folder[result.value].id, title: utf8_to_b64(result2.value)}))
            if (result3.ok) {
              this.LoadEventMpdel()
            }
          }
        }


      }

    },

    onColumnClick(index) {
      if (this.selectedColumn == index) {
        this.selectedColumn = -1
        return
      }
      this.selectedColumn = index
    }
  }
}
</script>

<style scoped>

</style>