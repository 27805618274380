<template>
  <div class="sidebar-container">
    <div class="sidebar-logo">
      <img src="/img/logo-bar.png">
    </div>
    <div class="sidebar-account">{{ name }}</div>
    <div>
      <ul>
        <li><router-link to="/dashboard">Главная</router-link></li>
        <li v-if="isSuper||isRequester||isLogist"><router-link to="/requests" @click="activeMenuItem=0">Запросы</router-link></li>
          <ul  class="submenu" v-if="(activeMenuItem == 0)">
            <li><router-link to="/requests">Текущие запросы</router-link></li>
            <li><router-link to="/requests/closed">Закрытые запросы</router-link></li>
            <li v-if="isSuper"><router-link to="/requests/reports">Отчеты</router-link></li>
          </ul>
        <li v-if="isSuper||isAdmin"><router-link to="/accounts" @click="activeMenuItem=0">Пользователи</router-link></li>
        <li v-if="isSuper||isAdmin"><router-link to="/logistquenue">Очередь логистов</router-link></li>

        <li
          v-for="item in menu" :key="item.id"
          @click="activeMenuItem=item.id"
        >
          <router-link :to="(item.route) ? item.route : '#'">{{ item.title }}</router-link>
          <ul  class="submenu" v-if="(item.submenu.length >0)&&(activeMenuItem == item.id)">
            <li
              v-for="subItem in item.submenu" :key="subItem.id"
            >
              <router-link :to="(subItem.route) ? subItem.route : '#'">{{ subItem.title }}</router-link>
            </li>
          </ul>
        </li>

        <li class="quit"><router-link to="/signout">Выход</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'SideBar',

  data: () => ({
    name: '',
    menu: [],
    activeMenuItem : 0
  }),

  computed: {
    isSuper () {
      return store.getters['account/isSuper']
    },
    isAdmin() {
      return store.getters['account/isAdmin']
    },
    isRequester() {
      return store.getters['account/isRequester']
    },
    isLogist() {
      return store.getters['account/isLogist']
    },

  },

  async mounted () {
    this.name = this.$store.getters['account/getUserName']
    const result = await this.$api.auth.getMenu()
    if (result.menuItems) {
     this.menu = result.menuItems
    }
  }
}
</script>

<style scoped>
.router-link-active {
  color: #326698;
  font-weight: 700;
  transition: all 0.3s;
}
</style>