<template>
  <div>
    <div class="card new">
      <div>Тип транспортного средства</div>
      <div class="half" style="margin-top: 8px">
        <select v-model.trim="localTruck.typeID">
          <option value="0"></option>
          <option v-for="item in truckTypes" :key="item.id" :value="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="row" style="margin-top: 16px; gap: 8px">
        <div>
          <div class="label">Марка</div>
          <input type="text" class="cursor" v-model.trim="localTruck.maker" />
        </div>
        <div>
          <div class="label">Модель</div>
          <input type="text" class="cursor" v-model.trim="localTruck.model" />
        </div>
        <div>
          <div class="label">Госномер</div>
          <input type="text" class="cursor" v-model.trim="localTruck.number" />
        </div>
        <div>
          <div class="label">Госномер прицепа</div>
          <input type="text" class="cursor" v-model.trim="localTruck.number2" />
        </div>
      </div>

      <div class="row" style="margin-top: 16px; gap: 8px">
        <div>
          <div class="label">VIN</div>
          <input type="text" class="cursor" v-model.trim="localTruck.vin" />
        </div>
        <div>
          <div class="label">Номер СТС</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.docnumber"
          />
        </div>
        <div>
          <div class="label">Гаражный номер</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.garagenumber"
          />
        </div>
        <div>
          <div class="label">Пропуск</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.passnumber"
          />
        </div>
      </div>

      <div class="row" style="margin-top: 16px; gap: 8px">
        <div>
          <div class="label">Способ загрузки</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.cargotype"
          />
        </div>
      </div>
      <div class="row" style="margin-top: 16px; gap: 8px">
        <div style="width: 142px">
          <div class="label">Высота</div>
          <input type="text" class="cursor" v-model.trim="localTruck.height" />
        </div>
        <div style="width: 142px">
          <div class="label">Ширина</div>
          <input type="text" class="cursor" v-model.trim="localTruck.width" />
        </div>
        <div style="width: 142px">
          <div class="label">Глубина</div>
          <input type="text" class="cursor" v-model.trim="localTruck.depth" />
        </div>
        <div style="width: 142px">
          <div class="label">Полезный объем</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.capacity"
          />
        </div>
        <div style="width: 142px">
          <div class="label">Грузоподъемность</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.liftingcapacity"
          />
        </div>
        <div style="width: 142px">
          <div class="label">Вместимость</div>
          <input type="text" class="cursor" v-model.trim="localTruck.places" />
        </div>
      </div>
      <div class="row" style="margin-top: 16px; gap: 8px">
        <div style="width: 142px">
          <div class="label">Полный вес</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.fullheight"
          />
        </div>
        <div style="width: 142px">
          <div class="label">Собственный вес</div>
          <input
            type="text"
            class="cursor"
            v-model.trim="localTruck.selfweight"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nmae: "TruckGeneral",

  data: () => ({
    truckTypes: [],

    UIblock: false,
  }),

  computed: {
    isSuper() {
      return this.$store.getters["account/isSuper"];
    },
    isAdmin() {
      return this.$store.getters["account/isAdmin"];
    },
    isLogist() {
      return this.$store.getters["account/isLogist"];
    },
    localTruck() {
      return this.$store.getters["truck/getData"];
    },
  },

  mounted() {
    this.loadTruckTypes();
  },

  methods: {
    async loadTruckTypes() {
      this.truckTypes = [];
      const result = await this.$api.cars.getTypes();
      if (result.list) {
        this.truckTypes = result.list;
      }
    },
  },
};
</script>

<style></style>
