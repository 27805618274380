<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Паспортные данные</h2>

    <div style="max-width: 600px">
      <div class="label" style="margin-top: 20px">
        Наименование паспортных данных
      </div>
      <div><input type="text" v-model="card.title" /></div>
      <div class="row" style="margin-top: 10px">
        <div class="quarter m-right-16">
          <div class="label">Серия паспорта</div>
          <div>
            <input type="text" v-model="card.serial" />
          </div>
        </div>

        <div class="quarter m-right-16">
          <div class="label">Номер паспорта</div>
          <div>
            <input type="text" v-model="card.number" />
          </div>
        </div>

        <div class="quarter m-right-16">
          <div class="label">Код подразделения</div>
          <div>
            <input type="text" v-model="card.code" />
          </div>
        </div>

        <div class="quarter m-right-16">
          <div class="label">Дата выдачи</div>
          <div>
            <input type="text" v-model="card.date" />
          </div>
        </div>
      </div>

      <div class="label" style="margin-top: 10px">Кеm выдан</div>
      <div><input type="text" v-model="card.owner" /></div>

      <div class="row" style="margin-top: 10px">
        <div class="quarter m-right-16">
          <div class="label">Дата рождения</div>
          <div>
            <input type="text" style="widt" v-model="card.birthday" />
          </div>
        </div>

        <div style="flex-grow: 1">
          <div class="label">Место рождения</div>
          <div>
            <input type="text" style="widt" v-model="card.birthplace" />
          </div>
        </div>
      </div>
      <div class="form" style="margin-top: 10px" v-if="card.id == 0">
        <div class="label">Скан паспорта</div>
        <files-upload-box ref="filesUploadBox" />
      </div>

      <div class="form new" v-if="card.id > 0">
        <files-list
          :title="'Сканы паспорта'"
          :module="'passport'"
          :sourceID="Number(card.id)"
          :readonly="false"
        />
      </div>

      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import FilesList from "@/components/app/FilesList.vue";
import FilesUploadBox from "@/components/app/ui/FilesUploadBox.vue";

export default {
  name: "PassportDetailsDlg",

  components: {
    PopupModal,
    FilesList,
    FilesUploadBox,
  },
  data: () => ({
    card: {
      id: 0,
      title: "",
      serial: "",
      number: "",
      date: "",
      code: "",
      owner: "",
      birthday: "",
      birthplace: "",
    },

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.card = opts.value;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      if (this.card.title == "") {
        this.$api.eventBus.emit("toast", {
          text: "Название паспортных данных не может быть пустым!",
          style: "error",
        });
        return;
      }

      if (this.card.number == "") {
        this.$api.eventBus.emit("toast", {
          text: "Номер паспорта не может быть пустым!",
          style: "error",
        });
        return;
      }

      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.card });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },

    async uploadFiles(passportDetailsID) {
      const ok = await this.$refs.filesUploadBox.uploadFiles(
        "passport",
        passportDetailsID
      );
      if (ok) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
