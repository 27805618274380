<template>
  <h2 style="margin-top: 0; margin-bottom: 10px" v-if="card.id == 0">
    Новое физическое лицо (<span v-if="card.nationID == 0">российское</span
    ><span v-if="card.nationID == 1">иностранное</span>)
  </h2>

  <div class="radio__row">
    <div class="input__radio-inner">Правовая форма</div>
    <div class="input__radio-inner" v-for="form in forms" :key="form.id">
      <input
        type="radio"
        name="form"
        class="input__radio"
        :id="'form' + form.id"
        v-model="localcard.formID"
        :value="form.id"
        @change="$emit('save', localcard)"
      /><label :for="'form' + form.id" class="input__label">{{
        form.title
      }}</label>
    </div>
  </div>

  <div class="label">Фамилия</div>
  <div class="half">
    <input
      type="text"
      v-model.trim="localcard.name1"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>
  <div class="label">Имя</div>
  <div class="half">
    <input
      type="text"
      v-model.trim="localcard.name2"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>
  <div class="label">Отчество</div>
  <div class="half">
    <input
      type="text"
      v-model.trim="localcard.name3"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>
  <div class="label">Дата рождения</div>
  <div class="eighth">
    <input
      type="text"
      v-model.trim="localcard.birthday"
      :disabled="!canEdit"
      @change="$emit('save', localcard)"
    />
  </div>

  <div class="label">ИНН</div>
  <div class="row-center">
    <div class="eighth m-right-8">
      <input-control
        :initvalue="localcard.inn"
        @accept="searchINN"
        :disabled="!canEdit"
      />
    </div>
    <i class="fa fa-spinner fa-spin" v-if="innState == 'loading'"></i>
    <i
      class="fa fa-check"
      style="color: limegreen"
      v-if="innState == 'new'"
    ></i>
  </div>

  <div v-if="card.nationID == 0">
    <div class="label">СНИЛС</div>
    <div class="eighth">
      <input
        type="text"
        v-model.trim="localcard.snils"
        :disabled="!canEdit"
        @change="$emit('save', localcard)"
      />
    </div>
  </div>
  <div v-if="localcard.formID == 2">
    <div class="label">ОРГНИП</div>
    <div class="eighth">
      <input
        type="text"
        v-model.trim="localcard.ogrn"
        :disabled="!canEdit"
        @change="$emit('save', localcard)"
      />
    </div>
  </div>
</template>

<script>
import InputControl from "@/components/app/ui/InputControl.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "PersonData",
  components: {
    InputControl,
  },

  props: {
    card: {
      type: Object,
      default: () => ({
        id: 1,
        nationID: 0,
        name1: "",
        name2: "",
        name3: "",
        ogrn: "",
      }),
    },
    wantSave: Boolean,
  },

  emits: ["save", "cantSave", "canSave", "reload"],

  watch: {
    wantSave(newvalue) {
      if (newvalue) {
        // значит кто-то наверху хочет сохранить данные и требует проверить их корректность.
        this.validate();
      }
    },

    card: {
      handler(newCard) {
        this.localcard = newCard;
      },
      deep: true,
    },
  },

  data: () => ({
    localcard: {
      formID: 0,
      nationID: 0,
      name1: "",
      name2: "",
      name3: "",
      birthday: "",
      inn: "",
      snils: "",
      ogrn: "",
      status: 1,
    },

    forms: [
      { id: 0, title: "Физическое лицо" },
      { id: 1, title: "Самозанятый" },
      { id: 2, title: "Индивидуальный предприниматель" },
    ],

    innState: "",
  }),

  computed: {
    canEdit() {
      return true;
    },
  },

  mounted() {
    this.localcard = this.card;
  },

  methods: {
    validate() {
      if (this.localcard.inn == "") {
        this.$api.eventBus.emit("toast", {
          text: "ИНН не может быть пустым",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }
      if (this.localcard.name1 == "") {
        this.$api.eventBus.emit("toast", {
          text: "Фамилия не может быть пустой",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }

      if (this.localcard.name2 == "") {
        this.$api.eventBus.emit("toast", {
          text: "Имя не может быть пустым",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }

      if (this.localcard.name3 == "") {
        this.$api.eventBus.emit("toast", {
          text: "Отчество не может быть пустым",
          style: "error",
        });
        this.$emit("cantSave");
        return;
      }

      this.$emit("canSave");
    },

    async searchINN(data) {
      this.innState = "";
      if (data.value !== "") {
        this.innState = "loading";
        this.localcard.inn = data.value;

        const result = await this.$api.contragetns.searchINN(
          utf8_to_b64(JSON.stringify(this.localcard))
        );
        if (result.card != null && result.card.id) {
          this.innState = "";

          this.$router.push(
            `/compcard/${result.card.id}?companyfilter=${this.localcard.companyfilter}`
          );

          this.$emit("reload", result.card.id);
          return;
        }
        this.innState = "new";
        this.$emit("save", this.localcard);
      }
    },
  },
};
</script>

<style></style>
