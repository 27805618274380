<template>
  <div class="card new">
    <div v-if="contacts.length == 0">Список договоров пуст</div>

    <table-box v-else :header="tableHeader" :body="tableBody" @edit="onEdit" />
  </div>
  <div class="row new">
    <div class="button small">
      <button class="white bg-prime-prime-blue" @click="onCreate">
        Дабавить
      </button>
    </div>
  </div>
</template>

<script>
import TableBox from "@/components/app/ui/TableBox.vue";
export default {
  name: "ContractsList",

  components: {
    TableBox,
  },

  props: {
    contragentID: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      { id: 3, title: "Тип", width: "15%", align: "left" },
      { id: 2, title: "Контрагент", width: "30%", align: "left" },
      { id: 4, title: "Комментарий", width: "80%", align: "left" },
    ],
    tableBody: [],

    contacts: [],
    contactTypes: [],
  }),

  mounted() {
    this.loadTypes();
    this.load();
  },

  methods: {
    async loadTypes() {},

    async load() {},

    async onCreate() {},

    async onEdit(cell) {
      console.log(cell);
    },
  },
};
</script>

<style></style>
