<template>
  <div class="tab-list">
    <a v-for="tab in tabs" :key="tab.id"
       class="tab-list__item" 
       :class="{'active' : tab.id == currentTabID}"
       href="#"
       @click="()=>{currentTabID = tab.id; $emit('selected', tab)}"
    >
      {{ tab.title }}
    </a>

  </div>
</template>

<script>
export default {
  name: 'TabsBox',
  props : {
    'tabs' : Array,
    'defaultTabID': Number
  },

  data: () =>({
    currentTabID: 0
  }),

  mounted () {
    this.currentTabID = this.defaultTabID
    const selectTab = this.tabs.find((element) => element.id == this.currentTabID)
    if (selectTab) {
      this.$emit('selected', selectTab)
    }
    
  }
}
</script>

<style>

</style>