<template>
  <!--
  <div>
    <input-control :init-value="'12'" @accept="accept" />
  </div>
  -->

  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Заказчики</h1></div>
    </div>
    <companies-list-view
      :canAdd="access.canEdit"
      :canEdit="access.canEdit"
      :companyfilter="'customers'"
    />
  </div>
</template>

<script>
import CompaniesListView from "@/components/finance/CompaniesListView.vue";
export default {
  name: "CustomersView",

  components: { CompaniesListView },

  data: () => ({
    access: {
      canEdit: false,
    },
  }),

  async mounted() {
    const result = await this.$api.auth.getAccessMode("customers");
    if (result.access) {
      this.access = result.access;
    }
  },

  methods: {
    accept(value) {
      console.log(value);
    },
  },
};
</script>

<style></style>
