<template>
  <loader-box v-if="loading" />
  <div :class="{ 'quarter-3': currentTabID != 5 }" v-else>
    <div>
      <h1>Карточка транспортного средства</h1>
    </div>
    <tabs-box
      v-if="tabs.length > 1"
      :tabs="tabs"
      :defaultTabID="0"
      @selected="onTabSelected"
    />
    <div v-if="currentTabID == 0">
      <truck-general />
    </div>

    <div class="row new" v-if="currentTabID == 0">
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="onSave">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { utf8_to_b64 } from "@/assets/js/base64.js";
import LoaderBox from "@/components/app/ui/LoaderBox.vue";
import TabsBox from "@/components/app/ui/TabsBox.vue";
import TruckGeneral from "@/components/trucks/TruckGeneral.vue";

export default {
  name: "TruckView",

  components: {
    LoaderBox,
    TabsBox,
    TruckGeneral,
  },

  data: () => ({
    loading: true,
    tabsArray: [
      { id: 0, title: "Общие данные" },
      { id: 1, title: "Пробег" },
      { id: 2, title: "Тех.обслуживание" },
      { id: 3, title: "Документы" },
    ],

    currentTabID: 0,
  }),

  computed: {
    truck() {
      return this.$store.getters["truck/getData"];
    },

    tabs() {
      /*
      let result = [];
      if (this.card.id == 0) {
        result.push(this.tabsArray[0]);
      } else {

        result = this.tabsArray.filter(
          (element) =>
            element.comptypeID === -1 ||
            element.comptypeID === this.card.comptypeID ||
            (element.comptypeID === -2 &&
              this.card.companyfilter == "company_staff")
              );
            }

            return result;
            */
      return this.tabsArray;
    },
  },

  mounted() {
    if (this.$route.params && this.$route.params["id"] != "0") {
      this.load(this.$route.params["id"]);
    }
    this.onTabSelected(this.tabsArray[0]);
  },

  unmounted() {
    this.$store.dispatch("truck/clear");
  },

  methods: {
    async load(truckID) {
      const result = await this.$api.cars.getTruck(truckID);
      if (result.truck) {
        this.$store.dispatch("truck/set", result.truck);
      }
      this.loading = false;
    },

    onTabSelected(tab) {
      this.currentTabID = tab.id;
    },
    async onSave() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      const truck = utf8_to_b64(JSON.stringify(this.truck));
      const result = await this.$api.cars.updateTruckGeneral({
        payload: truck,
      });
      if (result.ok) {
        this.$router.push("/company/trucks");
      }
      this.UIblock = false;
    },
  },
};
</script>

<style></style>
