<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Контакт</h2>
    <form action="#">
      <div>
        <div class="label" style="margin-top: 20px">Наименование контакта</div>
        <div><input type="text" v-model="card.title" /></div>
      </div>

      <div class="row">
        <div class="half m-right-8">
          <div class="label" style="margin-top: 20px">Тип контакта</div>
          <div>
            <select v-model="card.typeID" @change="onChange()">
              <option
                v-for="contactType in contactTypes"
                :key="contactType.id"
                :value="contactType.id"
              >
                {{ contactType.title }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <div class="label" style="margin-top: 20px">Контакт</div>
          <div><input :type="selectedContactType" v-model="card.value" /></div>
        </div>
      </div>
      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            type="submit"
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </form>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";

export default {
  name: "ContactDlg",
  components: {
    PopupModal,
  },
  data: () => ({
    card: {
      id: 0,
      title: "",
      typeID: 0,
      value: "",
    },
    contactTypes: [],
    selectedContactType: "text",

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.card = opts.value;
      this.contactTypes = opts.contactTypes;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      if (this.card.title == "") {
        this.$api.eventBus.emit("toast", {
          text: "Название контактов не может быть пустым!",
          style: "error",
        });
        return;
      }

      if (this.card.typeID == 0) {
        this.$api.eventBus.emit("toast", {
          text: "Выберите тип контакта!",
          style: "error",
        });
        return;
      }

      if (this.card.value == "") {
        this.$api.eventBus.emit("toast", {
          text: "Контакт не может быть пустым!",
          style: "error",
        });
        return;
      }

      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.card });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false, value: {} });
    },

    onChange() {
      this.selectedContactType = this.contactTypes.find(
        (element) => element.id === this.card.typeID
      ).inputtype;
    },
  },
};
</script>

<style></style>
