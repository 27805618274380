<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Запись в трудовую книжку</h2>

    <div style="max-width: 600px">
      <div class="row" style="margin-top: 10px">
        <div class="quarter m-right-16">
          <div class="label">Дата назначения</div>
          <div>
            <input type="text" v-model="card.date_start" />
          </div>
        </div>

        <div class="quarter m-right-16">
          <div class="label">Дата увольнения</div>
          <div>
            <input type="text" v-model="card.date_end" />
          </div>
        </div>
      </div>

      <div class="label" style="margin-top: 10px">Подразделение</div>
      <div>
        <select @change="selectDepartment()" v-model="card.departmentID">
          <option value="0"></option>
          <option v-for="item in departments" :key="item.id" :value="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="label" style="margin-top: 10px">Должность</div>
      <div>
        <select v-model="card.positionID">
          <option value="0"></option>
          <option v-for="item in positions" :key="item.id" :value="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="form" style="margin-top: 10px" v-if="card.id == 0">
        <div class="label">Скан трудового договора</div>
        <files-upload-box ref="filesUploadBox" />
      </div>

      <div class="form new" v-if="card.id > 0">
        <files-list
          :title="'Сканы трудового договора'"
          :module="'passport'"
          :sourceID="Number(card.id)"
          :readonly="false"
        />
      </div>

      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import FilesList from "@/components/app/FilesList.vue";
import FilesUploadBox from "@/components/app/ui/FilesUploadBox.vue";

export default {
  name: "EmploymentHistoryDlg",

  components: {
    PopupModal,
    FilesList,
    FilesUploadBox,
  },
  data: () => ({
    card: {
      id: 0,
      date_start: "",
      date_end: "",
      departmentID: 0,
      positionID: 0,
    },
    departments: [],
    positions: [],

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  mounted() {
    this.loadDepartments();
  },

  methods: {
    show(opts = {}) {
      this.card = opts.value;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      if (this.card.date_start == "") {
        this.$api.eventBus.emit("toast", {
          text: "Дата назначения на должность не может быть пустой!",
          style: "error",
        });
        return;
      }

      if (this.card.positionID == 0) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать должность!",
          style: "error",
        });
        return;
      }

      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.card });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },

    async uploadFiles(passportDetailsID) {
      const ok = await this.$refs.filesUploadBox.uploadFiles(
        "passport",
        passportDetailsID
      );
      if (ok) {
        return true;
      }
      return false;
    },

    async loadDepartments() {
      const result = await this.$api.company.getDepartments();
      if (result.list) {
        this.departments = result.list;
      }
    },

    async selectDepartment() {
      this.positions = [];
      const result = await this.$api.company.getPositionsByDepartment(
        this.card.departmentID
      );
      if (result.list) {
        this.positions = result.list;
        console.log("2", this.positions);
      }
    },
  },
};
</script>

<style></style>
