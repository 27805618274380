export default function (instance) {
  return {
    async getTaxes() {
      return await instance.get("finance/taxes");
    },

    async getPaymentsByOrder(orderID) {
      return await instance.get("finance/payments/order/" + orderID);
    },

    async createPayment(data) {
      return await instance.post("finance/payments/create", data);
    },
    async updatePayment(data) {
      return await instance.post("finance/payments/update", data);
    },
  };
}
