<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">{{ title }}</h2>

    <div class="wrap">
      <div style="margin-top: 20px">
        <div class="card new without-overflow">
          <div class="row-center label">
            <div class="input__radio-inner">
              <input
                type="radio"
                name="mode"
                class="input__radio"
                id="debet"
                v-model="mode"
                value="debet"
                @click="changeMode"
              /><label for="debet" class="input__label"> Дебет(приход) </label>
            </div>
            <div class="input__radio-inner">
              <input
                type="radio"
                name="mode"
                class="input__radio"
                id="credit"
                v-model="mode"
                value="credit"
                @click="changeMode"
              /><label for="credit" class="input__label">
                Кредит(расход)
              </label>
            </div>
          </div>
          <div class="label" style="margin-top: 16px">Наша компания</div>
          <select v-model="payment.ourcompanyID">
            <option
              v-for="item in ourcompanies"
              :key="item.id"
              :value="item.id"
            >
              {{ getContractorTitle(item) }}
            </option>
          </select>
          <div class="row-center" style="margin-top: 16px; gap: 16px">
            <div>
              <div class="label">Дата платежа (план)</div>
              <VueDatePicker
                v-model="payment.date_planned"
                model-type="yyyy-MM-dd"
                format="dd.MM.yyyy"
                auto-apply
              ></VueDatePicker>
            </div>
            <div>
              <div class="label">Дата платежа (факт)</div>
              <VueDatePicker
                v-model="payment.date_paid"
                model-type="yyyy-MM-dd"
                format="dd.MM.yyyy"
                auto-apply
              ></VueDatePicker>
            </div>
          </div>
          <div class="label" style="margin-top: 16px">Сумма платежа</div>
          <paymant-box
            class="label"
            :placeholder="'Сумма платежа'"
            :initvalue="mode == 'debet' ? payment.debet : payment.credit"
            :initTax="0"
            @updated="paymantUpdate"
            :reinit="reinit"
          />

          <div class="label" style="margin-top: 16px">Контрагент</div>
          <input-autocomplete
            :initvalue="getContractorTitle(payment.contragent)"
            :initID="payment.contragentID"
            :disabled="false"
            :searchFunc="searchContragent"
            :selectedFunc="selectedContragent"
            :reinit="reinit"
          />

          <div class="label" style="margin-top: 16px">Назначение платежа</div>
          <input type="text" v-model="payment.purpose" />
        </div>
      </div>

      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import PaymantBox from "@/components/finance/PaymantBox.vue";
import InputAutocomplete from "@/components/app/ui/InputAutocomplete.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";
import { getContragentShortName } from "@/assets/js/lib.js";

export default {
  name: "FindAddressDlg",

  components: {
    PopupModal,
    VueDatePicker,
    PaymantBox,
    InputAutocomplete,
  },
  data: () => ({
    title: "Заголовок",
    payment: {
      id: 0,
      ourcompanyID: 0,
      ourcompany: undefined,
    },

    paymentValue: 0, // введенная сумма

    ourcompanies: [], // Наши комнании (список)
    mode: "debet", // вид платежя - прихд или расход

    reinit: false, // реинициализация дочерних компонентов

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  mounted() {
    this.loadOurCompanies();
  },

  methods: {
    async loadOurCompanies() {
      const filter = utf8_to_b64(
        JSON.stringify({
          companyfilter: "ourcompany",
          page: 1,
          search: "",
        })
      );
      const result = await this.$api.contragetns.getContagents(filter);
      if (result.list) {
        this.ourcompanies = result.list;
      }
    },

    show(opts = {}) {
      this.title = opts.title;
      this.payment = opts.payment;
      this.mode = this.payment.credit != 0 ? "credit" : "debet";
      this.reinit = !this.reinit;

      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.payment });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },

    getContractorTitle(contragent) {
      return getContragentShortName(contragent);
    },

    paymantUpdate(value) {
      this.payment.taxID = value.taxID;
      this.paymentValue = value.value;
      this.changeMode();
    },

    async searchContragent(value) {
      let result = [];
      const response = await this.$api.contragetns.searchAll(
        utf8_to_b64(value)
      );
      if (response.list) {
        result = response.list;
        result.forEach((elem, index) => {
          result[index].title = getContragentShortName(elem);
        });
      }
      return result;
    },

    selectedContragent(value) {
      this.payment.contragentID = value.id;
    },

    changeMode() {
      if (this.mode == "debet") {
        this.payment.debet = this.paymentValue;
        this.payment.credit = 0;
        return;
      }
      this.payment.debet = 0;
      this.payment.credit = this.paymentValue;
    },
  },
};
</script>

<style scoped>
.wrap {
  width: 600px;
}

.select {
  width: 100%;
  height: 150px;
}

.unselected-address {
  font-size: 0.8em;
  font-style: italic;
}
</style>
