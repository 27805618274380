<template>
    <div class="none">
      <div class="table-pages row">
        <div>Страница {{ page }} из {{ total }}</div>
        <div class="row m-left">
          <div class="table-pages__page" :class="{'link' : page > 1}">	&lt;</div>
          <div class="table-pages__page" :class="{'link' : page > 1, 'this' : page == 1}">1</div>
          <div class="table-pages__page" :class="{'link' : page > 1, 'this' : page == 1}">1</div>
          <div class="table-pages__page">1</div>
          <div class="table-pages__page">...</div>
          <div class="table-pages__page link">1</div>
          <div class="table-pages__page link">1</div>
          <div class="table-pages__page"  :class="{'link' : page < total}">&gt;</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name : 'PagesBox',
  props : {
    total : Number,
    page : Number,
  }
}
</script>

<style>

</style>