<template>
  <div class="row">
    <div class="quarter-3 m-right-16">
      <div class="row-center">
        <div>
          <h1>
            Параметры запроса
            <span v-if="request.id > 0">
              {{ request.id }}
              <span style="font-size: 70%">
                от {{ request.date }} {{ request.time }}
              </span></span
            >
            <i
              v-if="isSuper && request.id > 0"
              style="margin: 0 20px"
              class="fa fa-chevron-left cursor"
              @click="requestPrev"
            ></i>
            <i
              v-if="isSuper"
              class="fa fa-chevron-right cursor"
              @click="requestNext"
            ></i>
          </h1>
        </div>
        <div
          class="m-left"
          style="font-size: 16px; font-weight: bold"
          v-if="request.id > 0"
          :class="{
            'state-new': request.state == 1,
            'state-error': request.state == 7,
          }"
        >
          {{ state }}
        </div>
      </div>
      <div class="form card new">
        <div class="label">Канал запроса</div>
        <div class="half" v-if="request.id > 0">
          <input
            type="text"
            v-model.trim="request.advertising"
            :disabled="!canEdit"
          />
        </div>
        <div class="half" v-else>
          <editable-select-box
            :getList="$api.order.getRequestSources"
            :add="$api.order.addRequestSources"
            @selected="onSourceSelected"
          />
        </div>
        <div class="label">Телефон Клиента</div>
        <div class="half">
          <input
            type="text"
            v-model.trim="request.phone"
            :disabled="!canEdit"
          />
        </div>
        <div class="label">Почта Клиента</div>
        <div class="half">
          <input
            type="text"
            v-model.trim="request.email"
            :disabled="!canEdit"
          />
        </div>

        <div class="label">Название/ФИО Клиента</div>
        <div>
          <input
            type="text"
            v-model.trim="request.customer"
            :disabled="!canEdit"
          />
        </div>

        <div class="subcard new">
          <div class="label">Тип ТС</div>
          <div class="half">
            <select v-model.trim="request.price.cartypeID">
              <option value="0"></option>
              <option
                v-for="item in cartypes_request"
                :key="item.id"
                :value="item.id"
              >
                {{ item.title }}
              </option>
            </select>
          </div>

          <div class="label">Место загрузки</div>
          <div class="quarter">
            <input type="text" v-model.trim="request.price.loading" />
          </div>

          <div class="label">Место выгрузки</div>
          <div class="quarter">
            <input type="text" v-model.trim="request.price.uploading" />
          </div>

          <div class="label">Вес груза</div>
          <div class="quarter">
            <input type="text" v-model.trim="request.price.weight" />
          </div>

          <div class="label">Объем груза</div>
          <div class="quarter">
            <input type="text" v-model.trim="request.price.volume" />
          </div>
        </div>

        <div class="label">Содержание запроса</div>
        <div>
          <div>
            <textarea
              v-model.trim="request.body"
              :disabled="!canEdit"
            ></textarea>
          </div>
        </div>
        <div class="label">Дата загрузки</div>
        <div class="row-center">
          <div class="eighth m-right-16">
            <input
              type="text"
              v-model.trim="request.loading_date"
              :disabled="!canEdit"
            />
          </div>
          <div class="m-right-8">
            <check-box
              :initvalue="request.permanent_load"
              :disabled="!canEdit"
              @onChange="
                (value) => {
                  request.permanent_load = value;
                }
              "
            />
          </div>
          <div class="v-center">Регулярная загрука</div>
        </div>
        <div class="label">Отдел</div>
        <div class="row">
          <div
            class="row-center"
            v-for="depart in tdepartment"
            :key="depart.id"
          >
            <div class="m-right-8">
              <radio-box
                :selectedValue="request.departmentID"
                :value="depart.id"
                :disabled="!canEdit"
                @onChange="
                  (value) => {
                    request.departmentID = value;
                  }
                "
              />
            </div>
            <div class="v-center m-right-16">{{ depart.title }}</div>
          </div>
        </div>

        <div v-if="request.id > 0 && !isLogist">
          <div class="label">Ответственный логист</div>
          <div v-if="request.id > 0">
            <select v-model.trim="request.logistID" :disabled="!isSuper">
              <option value="0"></option>
              <option
                v-for="logist in logists"
                :key="logist.id"
                :value="logist.id"
              >
                {{ logist.name1 }} {{ logist.name2 }} {{ logist.name3 }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form card new" v-if="request.id == 0">
        <h2 style="margin-top: 0">Файлы запроса</h2>
        <files-upload-box ref="filesUploadBox" />
      </div>

      <div class="form card new" v-if="request.id > 0">
        <files-list
          :title="'Исходные файлы запроса'"
          :module="'requests-init'"
          :sourceID="Number(request.id)"
          :readonly="!canEdit"
        />
      </div>

      <div class="form card new">
        <div class="row-center">
          <div class="m-right-8">
            <check-box
              :initvalue="request.notify_owner"
              :disabled="!canEdit"
              @onChange="
                (value) => {
                  request.notify_owner = value;
                }
              "
            />
          </div>
          <div class="v-center">Уведомить об отправке расчета Заказчику</div>
        </div>
      </div>

      <div class="form card new" v-if="canClose || requestClose">
        <div class="subcard new">
          <div class="label">Предложенная ставка</div>
          <div class="quarter">
            <input type="text" v-model.trim="request.price.price" />
          </div>
        </div>

        <div class="label">Отчет об обработке запроса</div>
        <div>
          <div>
            <textarea
              v-model.trim="request.report"
              :disabled="!isSuper && requestClose"
            ></textarea>
          </div>
        </div>
        <div class="label">Результат обработки запроса</div>
        <div>
          <select v-model.trim="stateID" :disabled="!isSuper && requestClose">
            <option
              v-for="state in requestStates"
              :key="state.id"
              :value="state.id"
            >
              {{ state.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="form card new" v-if="canClose">
        <files-list
          :title="'Документы и файлы'"
          :module="'requests'"
          :sourceID="Number(request.id)"
        />
      </div>

      <div class="row new">
        <div class="button">
          <button class="white bg-prime-prime-blue" @click="onSave(true)">
            Сохранить
          </button>
        </div>
        <div class="button">
          <button
            class="white bg-prime-prime-blue"
            @click="onSave(false)"
            v-if="isSuper"
          >
            Сохранить без перехода в списое запросов
          </button>
        </div>
      </div>
    </div>

    <div class="eighth m" v-if="request.id > 0 && (isSuper || isRequester)">
      <div class="row-center">
        <div><h1>&nbsp;</h1></div>
      </div>
      <div class="form card new">
        <div>Напомнить Логисту о данном запросе</div>
        <div class="button medium" style="margin-top: 10px">
          <button
            class="white bg-prime-prime-blue medium"
            @click="sendNotification('request/cutomer')"
          >
            Звонил Заказчик
          </button>
        </div>
      </div>

      <div class="row-center">
        <div><h1>&nbsp;</h1></div>
      </div>
      <div class="form card new" v-if="isSuper">
        <div>Шаблон письма логисту</div>
        <textarea v-model="letterTemplate"></textarea>
        <div class="button medium" style="margin-top: 10px">
          <button class="white bg-prime-prime-blue medium" @click="sendLetter">
            Отправить сообщение
          </button>
        </div>
        <div>
          <div class="button medium" style="margin-top: 10px">
            <button
              class="white bg-prime-prime-blue medium"
              @click="copyLetterTemplateToClipboard"
            >
              Скопировать в буффер
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { utf8_to_b64 } from "@/assets/js/base64.js";
import { convertBoolToInt, convertIntToB } from "@/assets/js/lib.js";
import FilesList from "@/components/app/FilesList.vue";
import CheckBox from "@/components/app/ui/CheckBox.vue";
import RadioBox from "@/components/app/ui/RadioBox.vue";
import FilesUploadBox from "@/components/app/ui/FilesUploadBox.vue";
import EditableSelectBox from "@/components/app/ui/EditableSelectBox.vue";

export default {
  name: "RequestPropsView",
  components: {
    FilesList,
    CheckBox,
    RadioBox,
    FilesUploadBox,
    EditableSelectBox,
  },
  computed: {
    isSuper() {
      return store.getters["account/isSuper"];
    },
    isAdmin() {
      return store.getters["account/isAdmin"];
    },
    isRequester() {
      return store.getters["account/isRequester"];
    },
    isLogist() {
      return store.getters["account/isLogist"];
    },
    canEdit() {
      return (
        store.getters["account/isSuper"] ||
        this.request.id == 0 ||
        (store.getters["account/isRequester"] && this.request.state == 1)
      );
    },
    canClose() {
      return (
        this.request.id > 0 &&
        (store.getters["account/isSuper"] ||
          store.getters["account/isLogist"]) &&
        (this.request.state == 4 ||
          this.request.state == 5 ||
          this.request.state == 9)
      );
    },
    requestClose() {
      return this.request.state == 6 || this.request.state == 7;
    },

    state() {
      const newState = this.requestStates.find(
        (element) => element.id == this.stateID
      );

      return newState ? newState.title : "";
    },
  },

  data: () => ({
    request: {
      id: 0,
      advertising: "",
      phone: "",
      email: "",
      customer: "",
      body: "",
      loading_date: "",
      permanent_load: false,
      departmentID: 0,
      notify_owner: false,
      logistID: 0,
      report: "",
      price: {
        cartypeID: 0,
        loading: "",
        uploading: "",
        weight: "",
        volume: "",
        price: "",
      },
      state: 0,
    },
    logists: [],
    tdepartment: [],
    requestStates: [],
    cartypes_request: [],
    stateID: 0,
    UIblock: false,
    letterTemplate: "",
  }),

  /* коды состояний

| 1 | Новая
| 2 | Ожидает
| 3 | Идет обработка
| 4 | Задержка
| 5 | Просрочена
| 6 | Создана заявка
| 7 | Отказ


  */

  async mounted() {
    this.loadTransportDepartment();
    this.loadLosists();
    this.loadCartypes_request();
    if (this.$route.params && this.$route.params["id"] != "0") {
      this.request.id = this.$route.params["id"];
      await this.load();
      this.stateID = this.request.state;
    }
  },

  methods: {
    async loadLosists() {
      this.logists = [];
      const result = await this.$api.auth.getLogists();
      if (result.logists) {
        this.logists = result.logists;
        this.createLetterTemplate();
      }
    },

    async loadCartypes_request() {
      this.logists = [];
      const result = await this.$api.order.getCartypes_request();
      if (result.list) {
        this.cartypes_request = result.list;
      }
    },

    async load() {
      const result = await this.$api.order.getRequest(this.request.id);
      if (result.request) {
        this.request = result.request;
        this.createLetterTemplate();
        this.request.permanent_load = convertIntToB(
          this.request.permanent_load
        );
        this.request.notify_owner = convertIntToB(this.request.notify_owner);

        this.requestStates = [];
        if (result.states) {
          this.requestStates = result.states;
        }
        if (this.isLogist && this.request.state <= 3) {
          const result2 = await this.$api.order.requestIsOpen(this.request.id);
          if (result2.state) {
            this.request.state = result2.state;
          }
        }
      }
    },

    async onSave(gotoRequestList) {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      if (this.request.advertising == "") {
        this.$api.eventBus.emit("toast", {
          text: "Канал запроса не может быть пустым!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }
      if (this.request.customer == "") {
        this.$api.eventBus.emit("toast", {
          text: "Название/ФИО клиента не может быть пустым!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }
      if (this.request.body == "") {
        this.$api.eventBus.emit("toast", {
          text: "Содержание запроса не может быть пустым!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      if (this.request.departmentID == 0) {
        this.$api.eventBus.emit("toast", {
          text: "Необходимо выбрать отдел!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      if (
        this.request.id > 0 &&
        this.stateID == 7 &&
        this.request.report.length <= 4 &&
        this.isLogist
      ) {
        this.$api.eventBus.emit("toast", {
          text: "Укажите причину отказа!",
          style: "error",
        });
        this.UIblock = false;
        return;
      }

      this.request.state = this.stateID;

      let request = {};
      request.id = this.request.id;
      request.advertising = utf8_to_b64(this.request.advertising);
      request.phone = utf8_to_b64(this.request.phone);
      request.email = utf8_to_b64(this.request.email);
      request.customer = utf8_to_b64(this.request.customer);
      request.body = utf8_to_b64(this.request.body);
      request.loading_date = utf8_to_b64(this.request.loading_date);
      request.permanent_load = convertBoolToInt(this.request.permanent_load);
      request.notify_owner = convertBoolToInt(this.request.notify_owner);
      request.departmentID = this.request.departmentID;
      request.logistID = this.request.logistID;
      request.report = utf8_to_b64(this.request.report);
      request.price = utf8_to_b64(JSON.stringify(this.request.price));
      request.state = this.request.state;

      const result = await this.$api.order.updateRequest(request);
      if (this.request.id == 0 && result.id) {
        const requestID = result.id;
        const ok = await this.$refs.filesUploadBox.uploadFiles(
          "requests-init",
          requestID
        );
        if (ok) {
          this.$router.push("/requests");
          this.UIblock = false;
          return;
        }
      }

      if (result.ok) {
        if (gotoRequestList) {
          this.$router.push("/requests");
        } else {
          this.$api.eventBus.emit("toast", {
            text: "Сохранено!",
            style: "success",
          });
        }
      }
      this.UIblock = false;
    },

    async loadTransportDepartment() {
      this.tdepartment = [];
      const result = await this.$api.company.getTransportDepartment();
      if (result.departments) {
        this.tdepartment = result.departments;
      }
    },

    async sendNotification() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      // let notificationData = {
      //   type: code,
      //   requestID: this.request.id,
      // };
      // const result = await this.$api.auth.sendNotification({
      //   data: utf8_to_b64(JSON.stringify(notificationData)),
      // });

      let logist = this.logists.find(
        (element) => element.id === this.request.logistID
      );

      let notificationData = {
        type: "letter",
        subject:
          "ЗАПРОС. Уведомление от Вашей CRM QTS logistics. Клиент запрашивает результат",
        body: `${this.getAppeal(logist)}!<br><br> По запросу #${
          this.request.id
        } Заказчик повторно запросил результат обработки! <br>    Открыть запрос Вы можете <a target="_blank" href="${
          window.location.origin
        }/?path=request/${this.request.id}">по этой ссылке</a>`,
        requestID: this.request.id,
      };
      const result = await this.$api.auth.sendLetter({
        data: utf8_to_b64(JSON.stringify(notificationData)),
      });

      if (result.ok) {
        this.$api.eventBus.emit("toast", {
          text: "Уведомление логисту отправлено!",
          style: "success",
        });
        this.UIblock = false;
        return;
      }
      this.$api.eventBus.emit("toast", {
        text: "Не удалось отправить уведомление!",
        style: "error",
      });
      this.UIblock = false;
    },

    async sendLetter() {
      if (this.UIblock) {
        this.$api.eventBus.emit("toast", {
          text: "Запрос обрабатывается. Ожидайте, пожалуйста.",
          style: "warning",
        });
        return;
      }
      this.UIblock = true;

      let notificationData = {
        type: "letter",
        subject: "Уведомление по запросу #" + this.request.id,
        body: this.letterTemplate,
        requestID: this.request.id,
      };
      const result = await this.$api.auth.sendLetter({
        data: utf8_to_b64(JSON.stringify(notificationData)),
      });
      if (result.ok) {
        this.$api.eventBus.emit("toast", {
          text: "Уведомление логисту отправлено!",
          style: "success",
        });
        this.UIblock = false;
        return;
      }
      this.$api.eventBus.emit("toast", {
        text: "Не удалось отправить уведомление!",
        style: "error",
      });
      this.UIblock = false;
    },

    onSourceSelected(event) {
      this.request.advertising = event.value;
    },

    async requestPrev() {
      this.request.id = this.request.id - 1;
      await this.load();
      this.stateID = this.request.state;
    },
    async requestNext() {
      this.request.id = this.request.id + 1;
      await this.load();
      this.stateID = this.request.state;
    },

    getAppeal(logist) {
      const appeal = logist.gender == "W" ? "Уважаемая" : "Уважаемый";
      return `${appeal}, ${logist.name2 + " " + logist.name3}`;
    },

    createLetterTemplate() {
      let logist = this.logists.find(
        (element) => element.id === this.request.logistID
      );

      if (logist) {
        let letterBody = "";
        if (this.request.state == 9) {
          letterBody =
            "расчет Клиенту давно отправлен, но результат неизвестен. Уточните, пожалуйста, результат и внесите его  в СРМ";
        }
        if (this.request.state == 4) {
          letterBody =
            " обработка идет длительное время.<br>Уточните, пожалуйста, статус запроса в СРМ";
        }

        this.letterTemplate = `${this.getAppeal(logist)}!<br><br>По запросу ${
          this.request.id
        } ${letterBody}<br><br><br>С уважением, Андрей Пучков.<br>Администратор CRM QTS `;
      }
    },

    copyed() {
      this.$api.eventBus.emit("toast", {
        text: "Скопировано!",
        style: "success",
      });
    },
    nocopyed() {
      this.$api.eventBus.emit("toast", {
        text: "Ошибка копирования!",
        style: "error",
      });
    },

    copyLetterTemplateToClipboard() {
      navigator.clipboard
        .writeText(this.letterTemplate)
        .then(this.copyed, this.nocopyed);
    },
  },
};
</script>

<style></style>
