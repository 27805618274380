import network from "./instance";
import authModule from "./auth";
import cookieModule from "./cookie";
import companyModule from "./company";
import carsModule from "./cars";
import orderModule from "./order";
import contragetnsModule from "./contragetns";
import driversModule from "./drivers";
import filesModule from "./files";
import externalModule from "./external";
import servicesModule from "./services";
import addressModule from "./address";
import cargoModule from "./cargo";
import financeModule from "./finance.js";
import unitsModule from "./units";
import storageModule from "./storage";

import { EventBus } from "./eventBus";

network.setEventBus(EventBus);

export default {
  auth: authModule(network),
  company: companyModule(network),
  cars: carsModule(network),
  order: orderModule(network),
  contragetns: contragetnsModule(network),
  drivers: driversModule(network),
  files: filesModule(network),
  external: externalModule(network),
  services: servicesModule(network),
  address: addressModule(network),
  cargo: cargoModule(network),
  finance: financeModule(network),
  units: unitsModule(network),
  storage: storageModule(network),

  cookie: cookieModule(),
  eventBus: EventBus,
};
