<template>
  <div>
    <div class="card new">
      <div class="row-center">
        <div>
          <h2>Исполнители</h2>
        </div>
      </div>
    </div>
    <table style="margin: 16px 0">
      <thead>
        <th width="20px">#</th>
        <th width="80px">Услуга</th>
        <th width="70px">Дата</th>
        <th width="40px">Время</th>
        <th width="300px">Адрес</th>
        <th width="200px">Исполнитель</th>
        <th>Ставка</th>
        <th>Налог</th>
        <th>Параметры исполнения</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in contractors" :key="item.id" class="cursor">
          <td>{{ index + 1 }}</td>
          <td
            :class="{
              green: item.typeID == loading,
              blue: item.typeID == unloading,
              gray: item.typeID == additional,
            }"
          >
            {{ item.typeTitle }}
          </td>
          <td>{{ item.dateStr }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.address }}</td>
          <td @click="editContractor(index, item)">
            {{ getContractorTitle(item) }}
          </td>
          <td>2</td>
          <td>3</td>
          <td @click="editContractor(index, item)">{{ item.cargo.comment }}</td>
        </tr>
        <tr v-if="contractors.length == 0">
          <td colspan="10">
            <i>Исполнители не заданы</i>
          </td>
        </tr>
      </tbody>
    </table>
    <search-easy-dlg ref="searcheasyDlg" />
  </div>
</template>

<script>
import SearchEasyDlg from "@/components/modals/app/SearchEasyDlg.vue";
import { getContragentShortName } from "@/assets/js/lib";

export default {
  name: "OrderContractors",

  components: {
    SearchEasyDlg,
  },

  computed: {
    canAdd() {
      return true;
    },
  },
  data: () => ({
    contractors: [],

    // константы
    loading: 1,
    unloading: 2,
    additional: 3,
  }),
  mounted() {
    this.load();
  },

  methods: {
    async load() {
      const result = await this.$api.order.getRouteForContractors(
        this.$store.getters["order/getID"]
      );

      if (result.list) {
        this.contractors = result.list;
      }
    },

    async editContractor(index) {
      const result = await this.$refs.searcheasyDlg.show({
        title: "Введите ИНН или название компании ",
        searchFunc: this.$api.contragetns.searchContractor,
        okButton: "ОК",
      });
      if (result.ok) {
        this.contractors[index].contractorID = result.value.id;
        const result2 = await this.$api.order.updateRoute(
          this.$store.getters["order/getID"],
          this.contractors[index]
        );
        if (result2.ok) {
          this.load();
        }
      }
    },

    getContractorTitle(node) {
      return getContragentShortName(node.contractor);
    },
  },
};
</script>

<style scoped>
.green {
  background-color: #e0eee0;
}

.blue {
  background-color: #dff3f9;
}
</style>
