<template>
  <div>
    <div class="input-wrap row-center">
      <input
        type="number"
        v-model="value"
        :placeholder="placeholder"
        @keyup="updated()"
      />&nbsp;{{ currency }}
    </div>
    <div class="row-center" style="margin-top: 8px">
      <div class="input__radio-inner" v-for="tax in taxes" :key="tax.id">
        <input
          type="radio"
          name="tax"
          class="input__radio"
          :id="'tax' + tax.id"
          v-model="taxID"
          :value="tax.id"
          @change="updated()"
        /><label :for="'tax' + tax.id" class="input__label">{{
          tax.title
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymantBox",

  props: {
    placeholder: { type: String, default: "" },
    currency: { type: String, default: "руб." },
    initvalue: { type: Number, default: 0 },
    initTax: { type: Number, default: 1 },
    reinit: { type: Boolean, default: false },
  },

  emits: ["updated"],

  data: () => ({
    value: 0,
    taxID: 1,
    taxes: [],
  }),

  watch: {
    initvalue: {
      handler(newValue) {
        this.value = newValue;
      },
      deep: true,
    },

    reinit: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
    this.init();
  },

  methods: {
    async load() {
      const result = await this.$api.finance.getTaxes();
      if (result.list) {
        this.taxes = result.list;
        if (this.taxID == 0 && this.taxes.length > 0) {
          this.taxID = this.taxes[0].id;
        }
      }
    },

    updated() {
      this.$emit("updated", { taxID: this.taxID, value: this.value });
    },

    init() {
      // инициалзиация
      this.taxID = this.initTax;
      this.value = this.initvalue;
      if (this.taxID == 0 && this.taxes.length > 0) {
        this.taxID = this.taxes[0].id;
      }
    },
  },
};
</script>

<style scoped>
.input-wrap {
  width: 150px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
