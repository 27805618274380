<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Заявки</h1></div>
      <div class="button m-left" v-if="isSuper || isCreator">
        <button
          class="white bg-prime-prime-blue"
          @click="$router.push('/neworder')"
        >
          добавить
        </button>
      </div>
    </div>
    <div class="card new">
      <div class="row-center-justify">
        <div>
          <h2>Текущие заявки</h2>
        </div>
        <search-box
          :placeholder="'Введите слово для поиска по всем текущим заявкам'"
          :style="'search--light'"
          @search="searchInCurrent"
        />
      </div>

      <orders-list :list="orders" @onClick="selectOrder" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { utf8_to_b64 } from "@/assets/js/base64.js";
import SearchBox from "@/components/app/ui/SearchBox.vue";
import OrdersList from "@/components/orders/OrdersList.vue";

export default {
  name: "OrderListView",

  components: {
    SearchBox,
    OrdersList,
  },

  computed: {
    isSuper() {
      return store.getters["account/isSuper"];
    },
    isCreator() {
      return false;
    },
  },

  data: () => ({
    orders: [],
    updateTimeout: null,
  }),

  mounted() {
    this.loadOrders();
    this.updateTimeout = setInterval(this.updateState, 60000);
  },

  unmounted() {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout);
    }
  },

  methods: {
    updateState() {
      this.loadOrders();
    },

    async loadOrders() {
      let filter = utf8_to_b64(JSON.stringify({ mode: "active" }));
      let result = await this.$api.order.getOrders(filter);
      if (result.list) {
        this.orders = result.list;
      }
    },

    selectOrder(orderID) {
      this.$router.push("/order/" + orderID);
    },

    async searchInCurrent(tags) {
      let filter = utf8_to_b64(JSON.stringify({ mode: "active", tags }));
      let result = await this.$api.order.getOrders(filter);
      if (result.list) {
        this.orders = result.list;
      }
    },
  },
};
</script>

<style></style>
