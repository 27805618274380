<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">Тип записи в маршруте</h2>

    <div style="max-width: 600px">
      <div class="label row-center" style="margin-top: 20px">
        <div v-for="item in routeTypes" :key="item.id">
          <div class="m-right-16">
            <input
              type="radio"
              name="routeType"
              class="input__radio"
              :id="'nation' + item.id"
              v-model="value.typeID"
              :value="item.id"
            /><label :for="'nation' + item.id" class="input__label">{{
              item.title
            }}</label>
          </div>
        </div>
      </div>

      <div class="row button meduim" style="margin-top: 24px">
        <div class="m-left m-right-8">
          <button
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";

export default {
  name: "RouteTypeDlg",

  components: {
    PopupModal,
  },
  data: () => ({
    value: {
      typeID: 0,
    },
    routeTypes: [],

    okButton: "ОК",
    cancelButton: "Отмена", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  mounted() {
    this.loadOrderTypes();
  },

  methods: {
    async loadOrderTypes() {
      const result = await this.$api.order.getRouteTypes();
      if (result.list) {
        this.routeTypes = result.list;
      }
    },

    show(opts = {}) {
      this.value = opts.value;
      this.okButton = opts.okButton;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: true, value: this.value });
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },
  },
};
</script>

<style></style>
