<template>
  <div class="row-center">
    <div class="flex-div-rub m-right-16">
      <select @change="$emit('selected', {value : selectedValue})" v-model="selectedValue">
        <option value=""></option>
        <option
          v-for="item in list" :key="item.id"
         :value="item.title">
         {{ item.title }}
        </option>
      </select>
    </div>
    <div>
      <div class="button-icon white bg-prime-prime-blue small" @click="onAdd">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
          <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
        </svg>

      </div>
    </div>
    <input-dlg ref="inputDlg" />
  </div>
</template>

<script>
import InputDlg from '@/components/modals/app/InputDlg.vue'
import { utf8_to_b64 } from '@/assets/js/base64.js'

export default {
  name: 'EditableSelectBox',

  components : {
    InputDlg
  },

  props : {
    'getList' : Function,
    'add' : Function,
  },

  emits : [
    'selected'
  ],

  data: () => ({
    list : [],
    selectedValue : ''
  }),

  async mounted () {
    this.load()
  },

  methods : {
    async load() {
      if (this.getList) {
        const result = await this.getList()
        if (result.list) {
          this.list = result.list
        }
      }
    },

    async onAdd() {
      const result = await this.$refs.inputDlg.show({
          title: 'Введите название нового',
          value: '',
          okButton: 'ОК',
      })

      if ((result.ok)&&(result.value !== '')&&(this.add)) {
        result.value = utf8_to_b64(result.value)
        const result2 = await this.add({title : result.value})
        if (result2.ok) {
          this.load()
        }
      }
    },
  }

}
</script>

<style>

</style>