<template>
  <div class="card new">
    <div v-if="PassportDetals.length == 0">Паспортные данные не заданы</div>

    <table-box v-else :header="tableHeader" :body="tableBody" @edit="onEdit" />
  </div>
  <div class="row new">
    <div class="button small">
      <button class="white bg-prime-prime-blue" @click="onCreate">
        Создать паспортные данные
      </button>
    </div>
  </div>

  <passport-details-dlg ref="passportDetailsDlg" />
</template>

<script>
import PassportDetailsDlg from "@/components/modals/company/PassportDetailsDlg.vue";
import TableBox from "@/components/app/ui/TableBox.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "PassportDetails",

  components: {
    PassportDetailsDlg,
    TableBox,
  },

  props: {
    contragentID: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      {
        id: 2,
        title: "Наименование паспортных данных",
        width: "80%",
        align: "left",
      },
    ],
    tableBody: [],

    PassportDetals: [],
  }),

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      if (this.contragentID > 0) {
        const result = await this.$api.contragetns.getPassportDetails(
          this.contragentID
        );
        this.tableBody = [];
        if (result.list) {
          this.PassportDetals = result.list;
          this.PassportDetals.forEach((element) => {
            this.tableBody.push({
              id: element.id,
              row: [
                { id: 0, value: element.id },
                { id: 1, value: element.title },
              ],
            });
          });
        }
      }
    },

    async onCreate() {
      let result = await this.$refs.passportDetailsDlg.show({
        value: {
          id: 0,
          title: "",
          serial: "",
          number: "",
          date: "",
          code: "",
          owner: "",
          birthday: "",
          birthplace: "",
        },
        okButton: "Создать",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updatePassportDetails({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          const passportDetailsID = result2.id;
          let result3 = await this.$refs.passportDetailsDlg.uploadFiles(
            passportDetailsID
          );
          if (result3) {
            this.load();
          }
        }
      }
    },

    async onEdit(cell) {
      let result = await this.$refs.passportDetailsDlg.show({
        value: this.PassportDetals[cell.row],
        okButton: "Сохранить",
      });
      if (result.ok && result.value) {
        result.value.contragentID = this.contragentID;
        const result2 = await this.$api.contragetns.updatePassportDetails({
          payload: utf8_to_b64(JSON.stringify(result.value)),
        });
        if (result2.id) {
          this.load();
        }
      }
    },
  },
};
</script>

<style></style>
