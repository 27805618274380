<template>
  <div :class="{'cursor' : !disabled}" @click="onClick">
    <img v-if="selectedValue!=value" src="/img/radiooff.png">
    <img v-if="selectedValue==value" src="/img/radioon.png">
  </div>
</template>

<script>
export default {
  name: "RadioBox",

  props: [
    'selectedValue',
    'value',
    'disabled'
  ],

  emits: [
    'onChange'
  ],

  methods : {
    onClick () {
      if (!this.disabled) {
        this.$emit('onChange', this.value);
      }
    }
  }

}
</script>

<style>

</style>