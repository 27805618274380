<template>
  <div class="quarter-3">
    <div class="row-center">
      <div v-if="card.companyfilter == 'ourcompany'">
        <h1>Наши компании</h1>
      </div>
      <div v-if="card.companyfilter == 'company_staff'">
        <h1>Сотрудники</h1>
      </div>
      <div v-if="card.companyfilter == 'customers'">
        <h1>Заказчики</h1>
      </div>
      <div v-if="card.companyfilter == 'contractors'">
        <h1>Исполнители</h1>
      </div>
    </div>
    <tabs-box
      v-if="tabs.length > 1"
      :tabs="tabs"
      :defaultTabID="0"
      @selected="onTabSelected"
    />
    <div v-if="currentTabID == 0">
      <div class="form card new" v-if="viewNations">
        <div class="radio__row">
          <div class="input__radio-inner">Юристикция(гражданство)</div>
          <div
            class="input__radio-inner"
            v-for="nation in nationsType"
            :key="nation.id"
          >
            <input
              type="radio"
              name="nationType"
              class="input__radio"
              :id="'nation' + nation.id"
              v-model="card.nationID"
              :value="nation.id"
              :disabled="card.id > 0"
            /><label :for="'nation' + nation.id" class="input__label">{{
              nation.title
            }}</label>
          </div>
        </div>
      </div>

      <div class="form card new" v-if="viewCompanyType">
        <div class="radio__row">
          <div class="input__radio-inner">Вид контрагента</div>
          <div
            class="input__radio-inner"
            v-for="compType in companyType"
            :key="compType.id"
          >
            <input
              type="radio"
              name="compType"
              class="input__radio"
              :id="'comp' + compType.id"
              v-model="card.comptypeID"
              :value="compType.id"
              :disabled="card.id > 0"
            /><label :for="'comp' + compType.id" class="input__label">{{
              compType.title
            }}</label>
          </div>
        </div>
      </div>

      <div class="form card new" v-if="card.comptypeID == 0">
        <person-data
          :card="card"
          :wantSave="wantSave"
          @save="onUpdateParam"
          @cantSave="cantSave"
          @canSave="canSave"
          @reload="reload"
        />
      </div>

      <div class="form card new" v-if="card.comptypeID == 1">
        <company-data
          :card="card"
          :wantSave="wantSave"
          @save="onUpdateParam"
          @cantSave="cantSave"
          @canSave="canSave"
          @reload="reload"
        />
      </div>
      <div class="row new">
        <div class="button">
          <button
            class="white bg-prime-prime-blue"
            @click="onSave"
            v-if="card.id > 0"
          >
            Сохранить
          </button>
          <button
            class="white bg-prime-prime-blue"
            @click="onSave"
            v-if="card.id == 0"
          >
            Создать
          </button>
        </div>
      </div>
    </div>

    <div v-if="currentTabID == 1">
      <bank-details :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 2">
      <contacts-list :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 3">
      <passport-details :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 4">
      <employment-history :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 5">
      <contracts-list :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 6">
      <contragent-finance-card :contragentID="card.id" />
    </div>

    <div v-if="currentTabID == 7">
      <contragent-account-card :contragentID="card.id" />
    </div>
  </div>
</template>

<script>
import TabsBox from "@/components/app/ui/TabsBox.vue";
import PersonData from "@/components/person/PersonData.vue";
import ContragentAccountCard from "@/components/person/ContragentAccountCard.vue";
import CompanyData from "@/components/company/CompanyData.vue";
import BankDetails from "@/components/finance/BankDetails.vue";
import ContragentFinanceCard from "@/components/finance/ContragentFinanceCard.vue";
import ContactsList from "@/components/misc/ContactsList.vue";
import ContractsList from "@/components/misc/ContractsList.vue";
import PassportDetails from "@/components/person/PassportDetails.vue";
import EmploymentHistory from "@/components/person/EmploymentHistory.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "CompCardView",

  components: {
    TabsBox,
    PersonData,
    ContragentAccountCard,
    CompanyData,
    BankDetails,
    ContragentFinanceCard,
    ContactsList,
    ContractsList,
    PassportDetails,
    EmploymentHistory,
  },

  data: () => ({
    // Карточка контрагента
    card: {
      id: 0,
      companyfilter: "",
      nationID: 0,
      comptypeID: 0,
      name1: "",
      name2: "",
      name3: "",
      formID: 0,
      birthday: "",
      inn: "",
      snils: "",
      ogrn: "",
      status: 1,
    },

    nationsType: [
      { id: 0, title: "российская" },
      { id: 1, title: "иностранная" },
    ],

    companyType: [
      { id: 0, title: "физическое лицо (ИП, самозанятый и т.п.)" },
      { id: 1, title: "юридическое лицо (ООО, ЗАО, ОАО, ТОО и т.п.)" },
    ],

    tabsArray: [
      { id: 0, comptypeID: -1, title: "Общие данные" },
      { id: 1, comptypeID: -1, title: "Банковские реквизиты" },
      { id: 2, comptypeID: -1, title: "Контакты" },
      { id: 3, comptypeID: 0, title: "Паспортаные данные" },
      { id: 4, comptypeID: -2, title: "Трудовая книжка" },
      { id: 5, comptypeID: -1, title: "Договоры" },
      { id: 6, comptypeID: -1, title: "Финансы" },
      { id: 7, comptypeID: -2, title: "Аккаунт" },
    ],
    currentTabID: 0,

    wantSave: false,
  }),

  computed: {
    tabs() {
      let result = [];
      if (this.card.id == 0) {
        result.push(this.tabsArray[0]);
      } else {
        result = this.tabsArray.filter(
          (element) =>
            element.comptypeID === -1 ||
            element.comptypeID === this.card.comptypeID ||
            (element.comptypeID === -2 &&
              this.card.companyfilter == "company_staff")
        );
      }

      return result;
    },

    viewNations() {
      return ["ourcompany", "customers", "contractors"].indexOf(
        this.card.companyfilter
      ) >= 0
        ? true
        : false;
    },

    viewCompanyType() {
      return ["ourcompany", "customers", "contractors"].indexOf(
        this.card.companyfilter
      ) >= 0
        ? true
        : false;
    },
  },

  mounted() {
    if (this.$route.params && this.$route.params["id"] != "0") {
      this.reload(this.$route.params["id"]);
    }
    if (this.$route.query) {
      if (this.$route.query["companyfilter"]) {
        this.card.companyfilter = this.$route.query["companyfilter"];
      }
    }
  },

  methods: {
    async reload(cardID) {
      this.card.id = cardID;
      this.load();
      setTimeout(() => {
        this.$api.eventBus.emit("refresh");
      }, 100);
    },

    onUpdateParam(params) {
      this.card = Object.assign(this.card, params);
    },

    onSave() {
      // запускает в дочерних компонентах процесс валидации
      this.wantSave = true;
    },

    cantSave() {
      // компонент дочерний сказал, что нельзя
      this.wantSave = false;
    },

    async canSave() {
      // компонента дочерний сказал, что можно.
      this.wantSave = false;
      if (this.currentTabID == 0) {
        const result = await this.$api.contragetns.updateContragent({
          payload: utf8_to_b64(JSON.stringify(this.card)),
        });
        if (result.id) {
          if (this.card.companyfilter == "ourcompany") {
            this.$router.push("/finance/company");
            return;
          }
          if (this.card.companyfilter == "company_staff") {
            this.$router.push("/company/staff");
            return;
          }
          this.$router.push(`/${this.card.companyfilter}`);
        }
      }
    },

    async load() {
      const result = await this.$api.contragetns.getContragent(this.card.id);
      if (result.card) {
        this.card = result.card;
        if (this.$route.query) {
          if (this.$route.query["companyfilter"]) {
            this.card.companyfilter = this.$route.query["companyfilter"];
          }
        }
      }
    },

    onTabSelected(tab) {
      this.currentTabID = tab.id;
    },
  },
};
</script>

<style></style>
