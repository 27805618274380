<template>
  <div class="card new">
    <div class="row-center">
      <div>
        <h2>Список компаний</h2>
      </div>
      <search-box
        class="m-left m-right-8"
        :placeholder="'Введите слово для поиска'"
        :style="'search--light'"
        @search="search"
      />
      <div class="button small" v-if="canAdd">
        <button class="white bg-prime-prime-blue" @click="onAdd">
          Добавить
        </button>
      </div>
    </div>

    <table-box :header="tableHeader" :body="tableBody" @edit="onEdit" />

    <pages-box :total="total" :page="page" />
  </div>
</template>

<script>
import SearchBox from "@/components/app/ui/SearchBox.vue";
import TableBox from "@/components/app/ui/TableBox.vue";
import PagesBox from "@/components/app/ui/PagesBox.vue";
import { getStatusIcon } from "@/assets/js/icons.js";
import { /*b64_to_utf8,*/ utf8_to_b64 } from "@/assets/js/base64.js";
export default {
  name: "CompaniesListView",
  components: {
    SearchBox,
    TableBox,
    PagesBox,
  },

  props: {
    canAdd: Boolean,
    canEdit: Boolean,
    companyfilter: String,
  },

  data: () => ({
    tableHeader: [
      { id: 2, title: "ID", width: "5%", align: "center" },
      { id: 3, title: "Иностранная", width: "10%", align: "center" },
      { id: 4, title: "Форма", width: "10%", align: "center" },
      { id: 5, title: "Наименование", width: "60%", align: "left" },
      { id: 6, title: "Статус", width: "10%", align: "center" },
    ],
    searchFilter: "",
    tableBody: [],
    companiesList: [],
    formtypes: [], // Правовые формы

    total: 10,
    page: 1,
  }),

  mounted() {
    this.loadContragents();
  },

  methods: {
    search() {},

    onAdd() {
      this.$router.push("/compcard/0?companyfilter=" + this.companyfilter);
    },

    async onEdit(cellCoord) {
      if (cellCoord.col == 4) {
        const result = await this.$api.contragetns.invertStatus(
          this.tableBody[cellCoord.row].id
        );
        if (result.ok) {
          this.loadContragents();
        }

        return;
      }

      this.$router.push(
        `/compcard/${this.tableBody[cellCoord.row].id}?companyfilter=${
          this.companyfilter
        }`
      );
    },

    getFormType(formtypeID) {
      return this.formtypes.find((element) => element.id === formtypeID).short;
    },

    async loadContragents() {
      const filter = utf8_to_b64(
        JSON.stringify({
          companyfilter: this.companyfilter,
          page: this.page,
          search: this.searchFilter,
        })
      );
      const result = await this.$api.contragetns.getContagents(filter);
      this.tableBody = [];
      if (result.list) {
        this.companiesList = result.list;
        this.formtypes = result.formtypes;

        this.companiesList.forEach((element) => {
          this.tableBody.push({
            id: element.id,
            row: [
              { id: 0, value: element.id },
              { id: 1, value: element.nationID == 0 ? "" : "V" },
              { id: 2, value: this.getFormType(element.formID) },
              {
                id: 3,
                value:
                  element.comptypeID == 1
                    ? element.name1
                    : `${element.name1} ${element.name2} ${element.name3}`,
              },
              { id: 4, value: getStatusIcon(element.status) },
            ],
          });
        });
      }
    },
  },
};
</script>

<style></style>
