export default function (instance) {
  return {
    async getTypes() {
      return await instance.get("cars/types");
    },

    async createType(data) {
      return await instance.post("cars/types/create", data);
    },

    async updateType(typeID, data) {
      return await instance.post("cars/types/update/" + typeID, data);
    },

    async getOurTrucks() {
      return await instance.get("cars/ourtrucks");
    },

    async createOurTruck(data) {
      return await instance.post("cars/ourtrucks/create", data);
    },

    async getTruck(truckID) {
      return await instance.get("cars/general/" + truckID);
    },

    async updateTruckGeneral(data) {
      return await instance.post("cars/general/update", data);
    },
  };
}
