<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Штатное расписание</h1></div>
    </div>
    <tabs-box :tabs="tabs" :defaultTabID="0" @selected="onTabSelected" />

    <div class="card new">
      <easy-dict
        v-if="currentTabID == 0"
        :title="'Подразделения'"
        :getList="$api.company.getDepartments"
        :add="$api.company.createDepartment"
        :update="$api.company.updateDepartment"
        :canAdd="accessDepartment.canEdit"
        :canEdit="accessDepartment.canEdit"
      />
      <select-dict
        v-if="currentTabID == 1"
        :title="'Должности'"
        :coldbname="'position'"
        :ext-colname="'Подразделение'"
        :ext-coldbname="'title'"
        :getList="$api.company.getPosition"
        :getExtList="$api.company.getDepartments"
        :add="$api.company.createPosition"
        :update="$api.company.updatePosition"
        :canAdd="accessDepartment.canEdit"
        :canEdit="accessDepartment.canEdit"
      />
    </div>
  </div>
</template>

<script>
import TabsBox from "@/components/app/ui/TabsBox.vue";
import EasyDict from "@/components/app/EasyDict.vue";
import SelectDict from "@/components/app/SelectDict.vue";

export default {
  name: "CompanyStructure",

  components: {
    TabsBox,
    EasyDict,
    SelectDict,
  },

  data: () => ({
    tabs: [
      { id: 0, title: "Подразделения" },
      { id: 1, title: "Должности" },
    ],
    currentTabID: -1,

    accessDepartment: {
      canEdit: false,
    },
  }),

  async mounted() {
    const result = await this.$api.auth.getAccessMode("department");
    if (result.access) {
      this.accessDepartment = result.access;
    }
  },

  methods: {
    onTabSelected(tab) {
      this.currentTabID = tab.id;
    },
  },
};
</script>

<style></style>
