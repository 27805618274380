export default function (instance) {
  return {
    setJWT(jwt) {
      instance.setJWT(jwt);
    },

    async signUp(payload) {
      return await instance.post("shop/signup", payload);
    },

    async signIn(payload) {
      return await instance.postWithoutCheck("account/signin", payload);
    },

    async validateJWT(jwt) {
      return await instance.get("account/validateJWT/" + jwt);
    },

    async getMenu() {
      return await instance.get("menu");
    },

    async getAccessMode(targetName) {
      return await instance.get("account/accessmode/" + targetName);
    },

    async updateRole(data) {
      return await instance.post("account/role/update", data);
    },

    async createRole(data) {
      return await instance.post("account/role/create", data);
    },

    async getRoles(filter) {
      return await instance.get("account/role/list/" + filter);
    },

    async getLogists() {
      return await instance.get("account/logists/list");
    },

    async updateLogists(data) {
      return await instance.post("account/logists/update", data);
    },

    async getPositionRoles(id) {
      return await instance.get("account/role/position/" + id);
    },

    async createAccount(data) {
      return await instance.post("account/user/create", data);
    },

    async createAccount2(data) {
      return await instance.post("account/user/create2", data);
    },

    async getAccount2(accountID) {
      return await instance.get("account/user/" + accountID);
    },

    async getAccounts(filter) {
      return await instance.get("account/user/list/" + filter);
    },

    async getAccessableManager(logistID) {
      return await instance.get(
        "account/logists/accessablemanager/" + logistID
      );
    },

    async updateAccount2(data) {
      return await instance.post("account/user/update2", data);
    },

    async getActions() {
      return await instance.get("account/actions");
    },

    async invertActionForRole(data) {
      return await instance.post("account/action/role/invert", data);
    },

    async addActionFolder(data) {
      return await instance.post("account/action/folder/add", data);
    },

    async addAction(data) {
      return await instance.post("account/action/add", data);
    },

    async checkNotifications() {
      return await instance.get("notifications/check");
    },

    async getNotificationsRecipients() {
      return await instance.get("notifications/recipients");
    },

    async getNotifications() {
      return await instance.get("notifications");
    },

    async getNotification(souceID) {
      return await instance.get("notifications/data/" + souceID);
    },

    async sendNotification(data) {
      return await instance.post("notifications/create", data);
    },

    async setNotificationReaded(souceID) {
      return await instance.post("notifications/readed/" + souceID, {});
    },

    async deleteNotification(souceID) {
      return await instance.post("notifications/delete/" + souceID, {});
    },

    async sendLetter(data) {
      return await instance.post("notifications/sendletter", data);
    },
  };
}
