<template>
  <div>
    <div class="card new">
      <div class="row-center">
        <div>
          <h2>Финансовая карта заявки</h2>
        </div>
        <div class="button small" v-if="canAdd" style="margin-left: auto">
          <button class="white bg-prime-prime-blue" @click="onAdd">
            Добавить
          </button>
        </div>
      </div>

      <div class="order-finance">
        <table>
          <thead>
            <tr>
              <th>Наша компания</th>
              <th width="100px">Дата платежа (план)</th>
              <th width="100px">Дата платежа (факт)</th>
              <th width="110px">Дебет</th>
              <th width="110px">Кредит</th>
              <th width="100px">Дата платежа (факт)</th>
              <th width="100px">Дата платежа (план)</th>
              <th>Контрагент</th>
              <th width="300px">Названичение платежа</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in payments.list"
              :key="item.id"
              @click="onEdit(item)"
            >
              <td class="w-left">{{ getContractorTitle(item.ourcompany) }}</td>
              <td>{{ item.debetPlannedDate }}</td>
              <td>{{ item.debetPaidDate }}</td>
              <td>
                <span v-if="item.debet > 0"
                  >{{ item.debet.toLocaleString() }} &#8381;</span
                >
              </td>
              <td>
                <span v-if="item.credit > 0"
                  >{{ item.credit.toLocaleString() }} &#8381;</span
                >
              </td>
              <td>{{ item.creditPaidDate }}</td>
              <td>{{ item.creditPlannedDate }}</td>
              <td class="w-left">{{ getContractorTitle(item.contragent) }}</td>
              <td class="w-left">{{ item.purpose }}</td>
            </tr>
            <tr>
              <td colspan="10"><hr /></td>
            </tr>
            <tr class="order-finance__total">
              <td colspan="2" class="w-right step">Итого начиcлено</td>
              <td colspan="2" class="w-right step" style="padding-right: 16px">
                {{ payments.debet.planned.toLocaleString() }} &#8381;
              </td>
              <td colspan="5" class="w-left step" style="padding-left: 16px">
                {{ payments.credit.planned.toLocaleString() }} &#8381;
              </td>
            </tr>
            <tr class="order-finance__total">
              <td colspan="2" class="w-right">Итого поступило/оплачено</td>
              <td colspan="2" class="w-right" style="padding-right: 16px">
                {{ payments.debet.paid.toLocaleString() }} &#8381;
              </td>
              <td colspan="5" class="w-left" style="padding-left: 16px">
                {{ payments.credit.paid.toLocaleString() }} &#8381;
              </td>
            </tr>
            <tr class="order-finance__total">
              <td colspan="2" class="w-right">Итого задолженность</td>
              <td
                colspan="2"
                class="w-right"
                style="padding-right: 16px"
                :class="{
                  error: payments.debet.paid - payments.debet.planned < 0,
                }"
              >
                {{
                  (
                    payments.debet.paid - payments.debet.planned
                  ).toLocaleString()
                }}
                &#8381;
              </td>
              <td
                colspan="5"
                class="w-left"
                style="padding-left: 16px"
                :class="{
                  error: payments.credit.paid - payments.credit.planned < 0,
                }"
              >
                {{
                  (
                    payments.credit.paid - payments.credit.planned
                  ).toLocaleString()
                }}
                &#8381;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <payment-dlg ref="paymentDlg" />
  </div>
</template>

<script>
import { getContragentShortName } from "@/assets/js/lib.js";
import PaymentDlg from "@/components/modals/finance/PaymentDlg.vue";

export default {
  name: "OrderFinance",

  components: {
    PaymentDlg,
  },

  computed: {
    canAdd() {
      return true;
    },
  },
  data: () => ({
    payments: {
      list: [],
      debet: {
        planned: 2000,
        paid: 1000,
      },
      credit: {
        planned: 3000.5,
        paid: 4000,
      },
    },

    // константы
    loading: 1,
    unloading: 2,
    additional: 3,
  }),
  mounted() {
    this.load();
  },

  methods: {
    async load() {
      const result = await this.$api.finance.getPaymentsByOrder(
        this.$store.getters["order/getID"]
      );

      if (result.payments) {
        this.payments = result.payments;
      }
    },

    async onEdit(item) {
      const result = await this.$refs.paymentDlg.show({
        title: "Добавление платежа",
        payment: item,
        okButton: "ОК",
      });
      if (result.ok) {
        const result2 = await this.$api.finance.updatePayment(result.value);
        if (result2.ok) {
          this.load();
        }
      }
    },

    async onAdd() {
      let result = await this.$refs.paymentDlg.show({
        title: "Добавление платежа",
        payment: {
          id: 0,
          ourcompanyID: 0,
          ourcompany: undefined,
          debet: 0,
          credit: 0,
          date_planned: "",
          date_paid: "",
          contragentID: 0,
          contragent: undefined,
          purpose: "",
          taxID: 0,
          taxrate: 0,
          tax: 0,
        },
        okButton: "ОК",
      });
      result.value.module = "order__manual";
      result.value.sourceID = this.$store.getters["order/getID"];
      const result2 = await this.$api.finance.createPayment(result.value);
      if (result2.ok) {
        this.load();
      }
    },

    getContractorTitle(contragent) {
      return getContragentShortName(contragent);
    },
  },
};
</script>

<style scoped>
/* 
order-finance--error
order-finance--success
order-finance--paid
*/
.step {
  /*padding-top: 32px;*/
  border-top: 32px solid #f5f7fa;
}
</style>
