<template>
  <div class="half">
    <div class="row-center">
      <div><h1>Очереди логистов</h1></div>
    </div>
    <div class="card new">
      <div class="label">Отдел</div>
      <div class="row">
        <div  class="row" 
            v-for="(depart, index) in tdepartment" :key="depart.id"
            @click="onDepartentClick(index)"
          >
            <div class="m-right-8"><input type="radio" v-model="departmentID" name="department" :value="depart.id"></div>
            <div class="v-center m-right-16">{{ depart.title }}</div>
          </div>
      </div>
    </div>
    <div class="card new">
      <div>
        <table>
          <thead>
            <th>#</th>
            <th>Логист</th>
            <th width="10%">Очередь</th>
            <th width="10%"></th>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in logists" :key="item.id"
            >
              <td>{{ index+1 }}</td>
              <td>{{ item.name1 }} {{ item.name2 }} {{ item.name3 }}</td>
              <td class="cursor" @click="onNext(index)">
                <div v-if="item.active == 1">&#10004;</div>
              </td>
              <td>
                <div class="row-center">
                  <div class="w30 cursor" @click="onUp(index)"><div v-if="index > 0">&uarr;</div></div>
                  <div class="w30 cursor" @click="onDown(index)"><div v-if="index < logists.length-1">&darr;</div></div>
                  <div class="w30 cursor" @click="onBlock(index)" ><div v-if="item.block==0">&#10004;</div><div v-else>&#10006;</div></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row new">
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="onSave">Сохранить</button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name:'LogistQuenueView',

  data: () => ({
    logists: [],
    departmentID: 0,
    tdepartment: [],
  }),

  mounted () {
    this.loadTransportDepartment()
  },

  methods: {
    async loadTransportDepartment () {
      this.tdepartment = []
      const result = await this.$api.company.getTransportDepartment()
      if (result.departments) {
        this.tdepartment = result.departments
        if (this.tdepartment.length > 0) {
          this.onDepartentClick(0)
        }
        
      }
    },

    async onDepartentClick(index) {
      this.departmentID = this.tdepartment[index].id
      const result = await this.$api.company.getLogistQuenue(this.departmentID)
      if (result.list) {
        this.logists = result.list
      }

    },

    onBlock(index) {
      this.logists[index].block = (this.logists[index].block == 0) ? 1 : 0
      if ((this.logists[index].block == 1)&&(this.logists[index].active == 1)) {
        this.logists[index].active = 0
        if (this.logists.length > 1) {
          if (index == this.logists.length-1) {
            this.logists[0].active = 1
            return
          }
          this.logists[index+1].active = 1
        }
      }

    },

    onNext(index) {
      if (this.logists[index].block == 0) {
        this.logists.forEach((element, index) => { this.logists[index].active = 0 })
        this.logists[index].active = 1
      }
    },

    onUp(index) {
      const tmp = this.logists[index-1]
      this.logists[index-1] = this.logists[index]
      this.logists[index] = tmp
    },

    onDown(index) {
      const tmp = this.logists[index+1]
      this.logists[index+1] = this.logists[index]
      this.logists[index] = tmp
    },

    async onSave() {
      let quenue = []
      let ord = 1
      this.logists.forEach((element) => { 
        quenue.push({ id: element.id, ord: ord, active: element.active, block: element.block })
        ord++
      })
      console.log(quenue);
      const result = await this.$api.company.setLogistQuenue(this.departmentID, quenue)
      if (result.ok) {
        console.log('ssss');
      }

    }

  }
}
</script>

<style>

</style>