<template>
  <popup-modal ref="popup">
    <div style="width: 640px">
      <h2 style="margin-top: 0">{{ title }}</h2>

      <div style="margin-top: 20px">
        <input-control :initvalue="initValue" @accept="search" />
      </div>

      <div style="margin-top: 20px">
        <select
          size="10"
          style="height: 300px"
          v-model="resultIndex"
          @dblclick="_confirm"
          :disabled="!ready"
        >
          <option
            v-for="(item, index) in resultList"
            :key="item.id"
            :value="index"
          >
            {{ item.name1 }}
            <span v-if="item.comptypeID == 0">
              {{ item.name2 }} {{ item.name3 }}
            </span>
          </option>
        </select>
      </div>

      <div class="row button meduim" style="margin-top: 16px">
        <div class="m-left m-right-8">
          <button
            v-if="resultIndex > -1"
            class="white bg-prime-prime-blue medium"
            @click.prevent="_confirm"
          >
            {{ okButton }}
          </button>
        </div>
        <div>
          <button class="white bg-prime-prime-blue medium" @click="_cancel">
            {{ cancelButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/app/PopupModal.vue";
import InputControl from "@/components/app/ui/InputControl.vue";
import { utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "SearchEasyDlg",
  components: {
    PopupModal,
    InputControl,
  },
  data: () => ({
    title: "",
    initValue: "",
    resultList: [],
    resultIndex: -1,
    searchFunc: null,
    ready: false,

    okButton: "Войти",
    cancelButton: "Отмена", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.resultList = [];
      this.resultIndex = -1;
      this.initValue = "";
      this.title = opts.title;
      this.okButton = opts.okButton;
      this.searchFunc = opts.searchFunc;
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _confirm() {
      this.$refs.popup.close();
      this.resolvePromise({
        ok: true,
        value: this.resultList[this.resultIndex],
      });
      this.initValue = "1";
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise({ ok: false });
    },

    async search(data) {
      this.ready = false;
      this.resultList = [{ id: 1, name1: "Идет поиск...." }];
      this.resultIndex = -1;

      if (this.searchFunc) {
        const result = await this.searchFunc(utf8_to_b64(data.value));
        if (result.list && result.list.length > 0) {
          this.resultList = result.list;
          this.ready = true;
          return;
        }
        this.resultList = [
          { id: 1, name1: "По Вашему запросу ничего не найдено." },
        ];
      }
    },
  },
};
</script>

<style></style>
