<template>
  <div class="row">
    <div class="quarter-3">
      <div class="row-center">
        <div><h1>Запросы</h1></div>
        <div class="button m-left" v-if="isSuper || isRequester">
          <button
            class="white bg-prime-prime-blue"
            @click="$router.push('/request/0')"
          >
            добавить
          </button>
        </div>
      </div>
      <div class="card new">
        <div class="row-center-justify">
          <div>
            <h2>Текущие запросы</h2>
          </div>
          <search-box
            :placeholder="'Введите слово для поиска по всем текущим запросам'"
            :style="'search--light'"
            @search="searchInCurrent"
          />
        </div>
        <requests-list :list="requestActive" @onClick="selectRequest" />
      </div>
    </div>
    <div class="eighth" v-if="isSuper" style="margin: 8px 0 0 16px">
      <div class="row-center">
        <div><h1>&nbsp;</h1></div>
      </div>
      <div class="form card new">
        <div style="text-align: center">Номер запроса</div>
        <div><input type="number" v-model="requestNumber" /></div>
        <div class="button medium" style="margin-top: 10px">
          <button class="white bg-prime-prime-blue medium" @click="openRequest">
            Открыть запрос
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { utf8_to_b64 } from "@/assets/js/base64.js";
import RequestsList from "@/components/orders/RequestsList.vue";
import SearchBox from "@/components/app/ui/SearchBox.vue";

export default {
  name: "RequestsView",

  components: {
    RequestsList,
    SearchBox,
  },

  computed: {
    isSuper() {
      return store.getters["account/isSuper"];
    },
    isRequester() {
      return store.getters["account/isRequester"];
    },
  },

  data: () => ({
    requestActive: [],
    updateTimeout: null,
    requestNumber: "",
  }),

  mounted() {
    this.loadRequest();
    this.updateTimeout = setInterval(this.updateState, 60000);
  },

  unmounted() {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout);
    }
  },

  methods: {
    updateState() {
      this.loadRequest();
    },

    async loadRequest() {
      let filter = utf8_to_b64(JSON.stringify({ mode: "active" }));
      let result = await this.$api.order.getRequests(filter);
      if (result.list) {
        this.requestActive = result.list;
      }
    },

    selectRequest(requestID) {
      this.$router.push("/request/" + requestID);
    },

    async searchInCurrent(tags) {
      let filter = utf8_to_b64(JSON.stringify({ mode: "active", tags }));
      let result = await this.$api.order.getRequests(filter);
      if (result.list) {
        this.requestActive = result.list;
      }
    },

    openRequest() {
      if (this.requestNumber != "") {
        this.$router.push("/request/" + this.requestNumber);
      }
    },
  },
};
</script>

<style></style>
