import { createStore } from "vuex";
import account from "./account";
import order from "./order";
import truck from "./truck";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    order,
    truck,
  },
});
