<template>
  <div class="quarter-3">
    <div class="row-center">
      <div><h1>Уведомление</h1></div>
    </div>
    <div class="form card new">
      <div class="label" v-if="id == 0">Получатель</div>
      <div class="half" v-if="id == 0">
        <select v-model.trim="recipientID">
          <option value="0"></option>
          <option v-for="recipient in recipients" :key="recipient.id"
            :value="recipient.id"
          >
            {{ recipient.name1 }} {{ recipient.name2 }} {{ recipient.name3 }}
          </option>
        </select>
      </div>
      <div class="label" v-if="id != 0">Отправитель</div>
      <div v-if="id != 0"><input type="text" v-model.trim="sender" readonly></div>
      <div class="label">Тема уведомления</div>
      <div><input type="text" v-model.trim="subject" :readonly="id > 0"></div>
      <div class="label">Содержание уведомления </div>
      <div v-if="html == 0">
        <div><textarea  v-model.trim="body" :readonly="id > 0"></textarea></div>
      </div>
      <div v-if="html == 1" style="margin: 10px 0;">
        <div v-html="body"></div>
      </div>
    </div>
    <div class="row new" v-if="id == 0">
      <div class="button">
        <button class="white bg-prime-prime-blue" @click="onSave">Отправить</button>
      </div>
    </div>
    <div class="row new" v-if="id > 0">
      <div class="button">
        <button class="white bg-red-dark" @click="onDelete">Удалить</button>
      </div>
    </div>

  </div>
</template>

<script>
import { utf8_to_b64 } from '@/assets/js/base64.js'
export default {
  name: 'NotificationPropView',

  data : () => ({
    id : 0,
    recipients : [],
    recipientID : 0,
    subject : '',
    body : '',
    sender : '',
    html : 0,

    UIblock: false,
  }),

  async mounted () {
    this.loadRecipients()
    if (this.$route.params&&(this.$route.params['id'] != '0')) {
      this.id = this.$route.params['id']
      await this.load()
    }
  },

  methods : {
    async loadRecipients() {
      const result = await this.$api.auth.getNotificationsRecipients()
      if (result.list) {
        this.recipients = result.list
      }
    },

    async onSave() {
      if (this.UIblock) {
        this.$api.eventBus.emit('toast', {text: 'Запрос обрабатывается. Ожидайте, пожалуйста.', style: 'warning'})
        return
      }
      this.UIblock = true

      if (this.recipientID == 0) {
        this.$api.eventBus.emit('toast', {text: 'Необходимо выбрать получателя!', style: 'error'})
        this.UIblock = false
        return
      }

      if (this.subject == '') {
        this.$api.eventBus.emit('toast', {text: 'Необходимо указать тему!', style: 'error'})
        this.UIblock = false
        return
      }

      let notificationData = {
        type : 'custom',
        recipientID : this.recipientID,
        subject : this.subject,
        body : this.body 
      }

      const result = await this.$api.auth.sendNotification({data : utf8_to_b64(JSON.stringify(notificationData))})
      if (result.ok) {
        this.$router.push('/notifications')
      }
      this.UIblock = false
    },

    async load() {
      const result = await this.$api.auth.getNotification(this.id)
      if (result.data) {
        this.sender = result.data.sender
        this.subject = result.data.subject
        this.body = result.data.body
        this.html = result.data.html

        if (result.data.new == 1) {
          await this.$api.auth.setNotificationReaded(this.id)
          this.$api.eventBus.emit('checkNotifications')
        }
      }
    },

    async onDelete() {
      const result = await this.$api.auth.deleteNotification(this.id)
      if (result.ok) {
        this.$router.push('/notifications')
      }

    }
  }
}
</script>

<style>

</style>