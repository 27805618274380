<template>
  <div>
    <div class="row-center">
      <div>
        <h2>{{ title }}</h2>
      </div>
      <search-box
        class="m-left m-right-8"
        :placeholder="'Введите слово для поиска'"
        :style="'search--light'"
        @search="search"
      />
      <div class="button small" v-if="canAdd">
        <button class="white bg-prime-prime-blue" @click="onAdd">
          Добавить
        </button>
      </div>
    </div>
    <div>
      <table-box :header="tableHeader" :body="tableBody" @edit="onEdit" />
    </div>
    <select-input-dlg ref="selectInputDlg" />
  </div>
</template>

<script>
import SearchBox from "@/components/app/ui/SearchBox.vue";
import TableBox from "@/components/app/ui/TableBox.vue";
import SelectInputDlg from "@/components/modals/app/SelectInputDlg.vue";
import { getStatusIcon } from "@/assets/js/icons.js";
import { /*b64_to_utf8,*/ utf8_to_b64 } from "@/assets/js/base64.js";

export default {
  name: "SelectDict",

  components: {
    SearchBox,
    TableBox,
    SelectInputDlg,
  },

  props: {
    title: String,
    coldbname: String,
    extColname: String,
    extColdbname: String,
    canAdd: Boolean,
    canEdit: Boolean,
    api: String,
    getList: Function,
    getExtList: Function,
    add: Function,
    update: Function,
  },

  data: () => ({
    tableHeader: [
      { id: 1, title: "ID", width: "10%", align: "center" },
      { id: 2, title: "", width: "30%", align: "left" },
      { id: 3, title: "", width: "50%", align: "left" },
      { id: 4, title: "Статус", width: "10%", align: "center" },
    ],

    tableBody: [],
    extList: [],
  }),

  async mounted() {
    if (this.title) {
      this.tableHeader[1].title = this.extColname;
      this.tableHeader[2].title = this.title;
    }
    this.loadBody();

    if (this.getExtList) {
      const result = await this.getExtList("active");
      if (result.list) {
        this.extList = [{ id: 0, title: "", status: 1 }].concat(result.list);
      }
    }
  },

  methods: {
    search() {},

    async loadBody() {
      this.tableBody = [];
      if (this.getList) {
        const result = await this.getList("all");
        if (result.list) {
          result.list.forEach((element) => {
            this.tableBody.push({
              id: element.id,
              extID: element[this.extColdbname + "ID"],
              status: element.status,
              row: [
                { id: 1, value: element.id },
                { id: 2, value: element[this.extColdbname] },
                { id: 3, value: element[this.coldbname] },
                { id: 4, value: getStatusIcon(element.status) },
              ],
            });
          });
        }
      }
    },

    async onAdd() {
      const result = await this.$refs.selectInputDlg.show({
        title: "Введите название нового " + this.title,
        selectValue: 0,
        inputValue: 0,
        list: this.extList,
        okButton: "ОК",
      });

      if (result.ok && result.input !== "" && result.select > 0 && this.add) {
        let data = { title: utf8_to_b64(result.input) };
        data[this.extColdbname + "ID"] = result.select;

        const result2 = await this.add(data);
        if (result2.ok) {
          this.loadBody();
        }
      }
    },
    async onEdit(event) {
      if (this.canEdit) {
        if (event.col < this.tableBody[event.row].row.length - 1) {
          // это не статус
          const result = await this.$refs.selectInputDlg.show({
            title: "Введите название нового " + this.title,
            selectValue: this.tableBody[event.row].extID,
            inputValue: this.tableBody[event.row].row[event.col].value,
            list: this.extList,
            okButton: "ОК",
          });

          if (
            result.ok &&
            result.input !== "" &&
            result.select > 0 &&
            this.update
          ) {
            let data = {
              id: this.tableBody[event.row].id,
              title: utf8_to_b64(result.input),
              status: this.tableBody[event.row].status,
            };
            data[this.extColdbname + "ID"] = result.select;

            const result2 = await this.update(data);
            if (result2.ok) {
              this.loadBody();
            }
          }

          return;
        }
        // это статус
        let data = {
          id: this.tableBody[event.row].id,
          status: this.tableBody[event.row].status ? 0 : 1,
          title: utf8_to_b64(this.tableBody[event.row].row[2].value),
        };
        data[this.extColdbname + "ID"] = this.tableBody[event.row].extID;
        console.log(data);
        const result2 = await this.update(data);
        if (result2.ok) {
          this.loadBody();
        }
      }
    },
  },
};
</script>

<style></style>
